<template>
  <!-- TODO i have here is valid  -->
  <div>
    <div :class="['form-group', paddingClass, 'custom-checkbox']">
      <!-- TODO this may be a problem because every has style and also different elements and i need somehow handle it differently-->

      <label v-if="label == 'Sesso'" class="control-label" style="padding-right:35px;">
        {{ translatedLabel }}*
      </label>
      <label v-else-if="label == 'comunicazioniPromozionali'" class="control-label" style="padding-right:35px;">
        <span style="font-weight: bold;margin: 8px; color: red;">*</span>
        {{ translatedLabel }}
      </label>
      <label v-else="" class="control-label" style="padding-right:30px;">
        {{ translatedLabel }}
      </label>

      <span v-if="settings.useLabelsAsValues" style="margin-left:18px">
        <input 
          class="form-check-input" 
          type="radio" 
          :class="required ? (isValid ? 'is-valid' : 'is-invalid') : ''" 
          :name="settings.labelYES" 
          :value="settings.labelYES" 
          v-model="localFieldValue" 
          @change="notifyParent"
        />
        <label class="form-check-label" style="padding-right:45px">{{ translatedLabelYES }}</label>

        <input 
          class="form-check-input" 
          type="radio" 
          :class="required ? (isValid ? 'is-valid' : 'is-invalid') : ''" 
          :name="settings.labelNO" 
          :value="settings.labelNO" 
          v-model="localFieldValue" 
          @change="notifyParent"
        />
        <label class="form-check-label">{{ translatedLabelNO }}</label>
      </span>

      <!-- use default true|false -->
      <div v-else style="margin-left:18px">
        <input 
          class="form-check-input" 
          type="radio" 
          :class="required ? (isValid ? 'is-valid' : 'is-invalid') : ''" 
          name="true" 
          v-bind:value="true" 
          v-model="localFieldValue" 
          @change="notifyParent" 
        />
        <label class="form-check-label" style="padding-right:45px;">{{ translatedLabelYES }}</label>

        <input 
          class="form-check-input" 
          type="radio" 
          :class="required ? (isValid ? 'is-valid' : 'is-invalid') : ''" 
          name="false" 
          v-bind:value="false" 
          v-model="localFieldValue" 
          @change="notifyParent" 
        />
        <label class="form-check-label">{{ translatedLabelNO }}</label>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'fieldRadioButtons',
  props: {
    label: String,
    settings: Object,
    translatedLabel: String,
    translatedLabelYES: String,
    translatedLabelNO: String,
    isValid: Boolean,
    fieldValue: [String, Boolean], // Prop passed from the parent component
    required: Boolean
  },
  data: function () {
    return {
      localFieldValue: this.fieldValue // Initialize localFieldValue with the value of fieldValue prop
    };
  },
  watch: {
    // Watch the fieldValue prop and update localFieldValue when it changes
    fieldValue(newValue) {
      this.localFieldValue = newValue;
    }
  },
  computed: {
    paddingClass() {
      return this.settings.useExtraPadding ? 'paddingpiu' : ''; 
    }
  },
  methods: {
    notifyParent(event) {
      this.$emit('changed', event); // Emit event to parent when the value changes
    }
  }
}
</script>

export const stepsData = [
  {
    step: 0,
    panel_title: "Letuecredenziali",
    fields: [
      {
        label: "TestoRegistrazione",
        type: "mark",
        required: false,
        isValid: false,
        value: "",
        on: true,
        fieldName: "testoRegistrazioneField",
        salesforceFieldName: ""
      },
      {
        label: "USERNAME",
        type: "text",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.username",
        on: true,
        fieldName: "USERNAMEField",
        salesforceFieldName: "Username__pc"
      },
      {
        label: "ModificaPasswordLabel",
        type: "h4",
        required: false,
        isValid: false,
        value: "",
        valuename: "",
        on: false,
        fieldName: "modificaPasswordLabelField",
        salesforceFieldName: ""
      },
      {
        label: "PASSWORD",
        type: "PASSWORD",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.password",
        settings: {
          showRevealButton: true
        },
        on: true,
        fieldName: "PASSWORDField",
        salesforceFieldName: "Password__pc"
      },
      {
        label: "filler",
        type: "filler",
        required: false,
        isValid: false,
        value: "",
        valuename: "",
        on: true,
        fieldName: "fillerField0",
        salesforceFieldName: ""
      },
      {
        label: "ConfermaPassword",
        type: "PASSWORD",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.pwd2",
        settings: {
          showRevealButton: true
        },
        on: true,
        fieldName: "confermaPasswordField",
        salesforceFieldName: ""
      },
    ]
  },
  {
    step: 1,
    panel_title: "DatiPersonali",
    fields: [
      {
        label: "Nome",
        type: "text",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.nome",
        salesforceFieldName: "FirstName",
        on: true,
        fieldName: "nomeField"
      },
      {
        label: "Cognome",
        type: "text",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.cognome",
        salesforceFieldName: "LastName",
        on: true,
        fieldName: "cognomeField"
      },
      {
        label: "Sesso",
        type: "fieldRadioButtons",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.sesso",
        salesforceFieldName: "Sesso__c",
        settings: {
          labelYES: "F",
          labelNO: "M",
          useLabelsAsValues: true,
          inSeparatedRow: false
        },
        on: true,
        fieldName: "sessoField"
      },
      {
        label: "filler",
        type: "filler",
        required: false,
        isValid: false,
        value: "",
        valuename: "",
        on: true,
        fieldName: "fillerField1",
        salesforceFieldName: ""
      },
      {
        label: "Prefisso",
        type: "fieldPickList",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.prefissoCellulare",
        options: [],
        settings: {
          label2: "Scegliunaopzione"
        },
        on: true,
        fieldName: "prefissoCellulareField",
        salesforceFieldName: "PersonMobilePhone",
        queryFields: {
          obj: 'Nazione__c',
          fields: ['Prefisso_internazionale__c', 'Name']
        }
      },
      {
        label: "Cellulare",
        type: "tel",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.cellulare",
        salesforceFieldName: "PersonMobilePhone",
        on: true,
        fieldName: "cellulareField"
      },
      {
        label: "Prefisso",
        type: "fieldPickList",
        required: false,
        isValid: false,
        value: "",
        valuename: "user.prefissoTelefono2",
        options: [],
        settings: {
          label2: "Scegliunaopzione"
        },
        on: true,
        fieldName: "prefissoCellulare2Field",
        salesforceFieldName: "Phone_2__c",
        queryFields: {
          obj: 'Nazione__c',
          fields: ['Prefisso_internazionale__c', 'Name']
        }
      },
      {
        label: "AltroCellulare",
        type: "tel",
        required: false,
        isValid: false,
        value: "",
        valuename: "user.telefono2",
        salesforceFieldName: "Phone_2__c",
        on: true,
        fieldName: "altroCellulareField"
      },
      {
        label: "E-Mail",
        type: "email",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.email",
        salesforceFieldName: "PersonEmail",
        settings: {
          isDisabled: false
        },
        on: true,
        fieldName: "eMailField"
      },
      {
        label: "MailSecondaria",
        type: "email",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.email2",
        salesforceFieldName: "Email__c",
        on: true,
        fieldName: "mailSecondariaField"
      },
      {
        label: "Conferma Email",
        type: "email",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.confermaemail",
        salesforceFieldName: "",
        on: true,
        fieldName: "confermaEmailField"
      },
      {
        label: "filler",
        type: "filler",
        required: false,
        isValid: false,
        value: "",
        valuename: "",
        on: true,
        fieldName: "fillerField2",
        salesforceFieldName: ""
      },
      {
        label: "letto_e_accetto",
        type: "checkbox",
        required: true,
        isValid: false,
        value: "",
        valuename: "user.privacy",
        settings: {
          withFileLink: true,
          fileLabel: "InformativaPrivacy",
          inSeparatedRow: true
        },
        on: true,
        fieldName: "lettoeaccettoField",
        salesforceFieldName: "Privacy_Consenso_Acquisito__pc"
      },
      {
        label: "filler",
        type: "filler",
        required: false,
        isValid: false,
        value: "",
        valuename: "",
        on: true,
        fieldName: "fillerField3",
        salesforceFieldName: ""
      },
      {
        label: "comunicazioniPromozionali",
        type: "fieldRadioButtons",
        required: true,
        isValid: true,
        value: true,
        valuename: "user.marketing",
        settings: {
          labelYES: "Si",
          labelNO: "No",
          inSeparatedRow: true,
          useLabelsAsValues: false
        },
        on: true,
        fieldName: "comunicazioniPromozionaliField",
        salesforceFieldName: "Privacy_Marketing__pc"
      }
    ]
  },
  {
    step: 2,
    panel_title: "Anagrafica",
    fields: [
      {
        label: "DatadiNascita",
        type: "date",
        required: true,
        value: "",
        valuename: "user.nascita.datanascita",
        isValid: false,
        on: true,
        fieldName: "datadiNascitaField",
        salesforceFieldName: "Data_di_nascita__c"
      },
      {
        label: "haiCF",
        type: "fieldRadioButtons",
        required: true,
        value: "",
        valuename: "user.hacodFisc",
        settings: {
          labelYES: "Si",
          labelNO: "No",
          inSeparatedRow: false,
          useLabelsAsValues: true
        },
        isValid: false,
        on: false,
        fieldName: "haiCFField",
        salesforceFieldName: ""
      },
      {
        label: "CodiceFiscale",
        type: "text",
        required: true,
        value: "",
        valuename: "user.nascita.codicefiscale",
        isValid: false,
        on: false,
        fieldName: "codiceFiscaleField",
        salesforceFieldName: "CF__c"
      },
      {
        label: "CittàdiNascita",
        type: "text",
        required: true,
        value: "",
        valuename: "user.nascita.cittanascita",
        isValid: false,
        on: true,
        fieldName: "cittdiNascitaField",
        salesforceFieldName: "Citta_di_nascita__c"
      },
      {
        label: "ProvinciadiNascita",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.nascita.provincianascita",
        settings: {
          label2: "Scegliunaopzione"
        },
        options: [],
        isValid: false,
        on: false,
        fieldName: "provinciadiNascitaField",
        salesforceFieldName: "Provincia_di_Nascita__c"
      },
      {
        label: "NazionediNascita",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.nascita.nazionenascita",
        settings: {
          label2: "Scegliunaopzione"
        },
        options: [],
        isValid: false,
        on: true,
        fieldName: "nazionediNascitaField",
        salesforceFieldName: "Nazione_di_nascita__c"
      },
      {
        type: "filler",
        required: false,
        isValid: false,
        on: true,
        fieldName: "unknownField",
        salesforceFieldName: ""
      },
      {
        label: "IndirizzoResidenza",
        type: "text",
        required: true,
        value: "",
        valuename: "user.residenza.indirizzoresidenza",
        isValid: false,
        on: true,
        fieldName: "indirizzoResidenzaField",
        salesforceFieldName: "PersonMailingStreet"
      },
      {
        label: "CittàdiResidenza",
        type: "text",
        required: true,
        value: "",
        valuename: "user.residenza.cittaresidenza",
        isValid: false,
        on: true,
        fieldName: "cittdiResidenzaField",
        salesforceFieldName: "PersonMailingCity"
      },
      {
        label: "CAP",
        type: "text",
        required: true,
        value: "",
        valuename: "user.residenza.capresidenza",
        isValid: false,
        on: true,
        fieldName: "cAPField",
        salesforceFieldName: "PersonMailingPostalCode"
      },
      {
        label: "ProvinciadiResidenza",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.residenza.provinciaresidenza",
        settings: {
          label2: "Scegliunaopzione"
        },
        options: [],
        isValid: false,
        on: false,
        fieldName: "provinciadiResidenzaField",
        salesforceFieldName: "PersonMailingState"
      },
      {
        label: "NazionediResidenza",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.residenza.nazioneresidenza",
        settings: {
          label2: "Scegliunaopzione"
        },
        options: [],
        isValid: false,
        on: true,
        fieldName: "nazionediResidenzaField",
        salesforceFieldName: "PersonMailingCountry"
      },
      {
        type: "filler",
        required: false,
        isValid: false,
        on: true,
        fieldName: "unknownField",
        salesforceFieldName: ""
      },
      {
        label: "Cittadinanza",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.residenza.cittadinanza",
        settings: {
          label2: "Scegliunaopzione"
        },
        options: [],
        isValid: false,
        on: true,
        fieldName: "cittadinanzaField",
        salesforceFieldName: "Cittadinanza__c"
      },
      {
        label: "SecondaCittadinanza",
        type: "text",
        required: false,
        value: "",
        valuename: "user.residenza.cittadinanza2",
        isValid: false,
        on: true,
        fieldName: "secondaCittadinanzaField",
        salesforceFieldName: "Cittadinanza_2__c"
      }
    ]
  },
  {
    step: 3,
    panel_title: "Studi",
    fields: [
      {
        label: "QualificaoTitolodiStudio",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.studio.titolostudio",
        salesforceFieldName: "Titolo_di_studio__c",
        settings: {},
        options: [],
        isValid: false,
        on: true,
        fieldName: "qualificaoTitolodiStudioField"
      },
      {
        label: "IndirizzodiStudio",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.studio.indirizzostudio",
        salesforceFieldName: "Indirizzo_titolo__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "indirizzodiStudioField"
      },
      {
        label: "Altro",
        type: "text",
        required: true,
        value: "",
        valuename: "user.studio.altroind",
        salesforceFieldName: "Altro_studio__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "altroField"
      },
      {
        label: "Annoconseguimentoqualifica",
        type: "tel",
        required: true,
        value: "",
        valuename: "user.studio.annotitolo",
        salesforceFieldName: "Anno_diploma__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "annoconseguimentoqualificaField"
      },
      {
        label: "NazioneIstituto",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.studio.nazioneistituto",
        salesforceFieldName: "Nazione_Istituto__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "nazioneIstitutoField"
      },
      {
        label: "CittàIstituto",
        type: "text",
        required: true,
        value: "",
        valuename: "user.studio.cittaistituto",
        salesforceFieldName: "Citta_Istituto__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "cittaIstitutoField"
      },
      {
        type: "filler",
        required: false,
        isValid: false,
        on: true,
        fieldName: "unknownField",
        salesforceFieldName: ""
      },
      {
        label: "RegioneIstituto",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.studio.regioneistituto",
        salesforceFieldName: "Regione_Istituto__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "regioneIstitutoField"
      },
      {
        label: "ProvinciaIstituto",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.studio.provinciaistituto",
        salesforceFieldName: "Provincia_Istituto__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "provinciaIstitutoField"
      },
      {
        label: "CittàIstituto",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.studio.cittaistituto",
        salesforceFieldName: "Citta_Istituto__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "cittaIstitutoFieldPick"
      },
      {
        label: "NomeIstituto",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.studio.istituto",
        salesforceFieldName: "Istituto__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "istitutoFieldPick"
      },
      {
        label: "NomeIstituto",
        type: "text",
        required: true,
        value: "",
        valuename: "user.studio.nomeistituto",
        salesforceFieldName: "Nome_Istituto__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "nomeIstitutoField12"
      },
      {
        label: "FrequentatoAlma",
        type: "fieldRadioButtons",
        required: true,
        value: "",
        valuename: "user.studio.altrescuole",
        salesforceFieldName: "isAltreScuole__c",
        settings: {
          labelYES: "Si",
          labelNO: "No",
          inSeparatedRow: false,
          useLabelsAsValues: true
        },
        options: [],
        isValid: false,
        on: true,
        fieldName: "frequentatoAlmaField"
      },
      {
        label: "AltraScuolaCucina",
        type: "text",
        required: true,
        value: "",
        valuename: "user.studio.altrascuolacucina",
        salesforceFieldName: "Altre_Scuole_Cucina__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "altraScuolaCucinaField"
      },
      {
        label: "ConosciutoAlma",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.studio.conosciutoalma",
        salesforceFieldName: "ConosciutoALMA__c",
        settings: {},
        options: [],
        isValid: false,
        on: true,
        fieldName: "conosciutoAlmaField"
      },
      {
        label: "Altro",
        type: "text",
        required: true,
        value: "",
        valuename: "user.studio.altroCA",
        salesforceFieldName: "Altro_conosciuto_Alma__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "altroConosciutoAlmaField"
      },
      {
        label: "labelLivelloInglese",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.studio.levelOfEnglish",
        salesforceFieldName: "Livello_Inglese__c",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "livelloIngleseField"
      },
      {
        label: "labelUploadEnglishCertificate",
        type: "fieldFile",
        required: false,
        value: "",
        valuename: "",
        salesforceFieldName: "",
        settings: {
          inSeparatedRow: true,
          maxMB:4.5
        },
        options: [],
        isValid: false,
        on: false,
        fieldName: "uploadEnglishCertificateField"
      }
    ]
    
  },
  {
    step: 4,
    panel_title: "InserireCurriculum",
    fields: [
      {
        label: "CaricaCurriculum",
        type: "fieldFile",
        required: true,
        value: "",
        valuename: "user.fileInfoCurriculum",
        settings: {
          inSeparatedRow: true,
          maxMB:4.5
        },
        isValid: false,
        on: true,
        fieldName: "caricaCurriculumField"
      },
      {
        label: "CaricaMotivationalLetter",
        type: "fieldFile",
        required: true,
        value: "",
        valuename: "user.fileInfoMotivationalLetter",
        settings: {
          inSeparatedRow: true,
          maxMB:4.5
        },
        isValid: false,
        on: false,
        fieldName: "caricaMotivationalLetterField"
      }
    ]
  },
  {
    step: 5,
    panel_title: "Documento di riconoscimento",
    fields: [
      {
        label: "TipoDocumento",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.documentoRic.tipoDoc",
        settings: {
          label2: "Scegliunaopzione"
        },
        options: [],
        isValid: false,
        on: true,
        fieldName: "tipoDocumentoField",
        salesforceFieldName: "Tipo_Documento__c"
      },
      {
        label: "Numero",
        type: "text",
        required: true,
        value: "",
        valuename: "user.documentoRic.numero",
        isValid: false,
        on: true,
        fieldName: "numeroField",
        salesforceFieldName: "Numero__c"
      },
      {
        label: "DataRilascio",
        type: "date",
        required: true,
        value: "",
        valuename: "user.documentoRic.dataRil",
        isValid: false,
        on: true,
        fieldName: "dataRilascioField",
        salesforceFieldName: "Data_Rilascio__c"
      },
      {
        label: "DataScadenza",
        type: "date",
        required: true,
        value: "",
        valuename: "user.documentoRic.dataScad",
        isValid: false,
        on: true,
        fieldName: "dataScadenzaField",
        salesforceFieldName: "Data_Scadenza__c",
      },
      {
        label: "NazioneRilascio",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.documentoRic.statoRilascio.Name",
        settings: {
          label2: "Scegliunaopzione"
        },
        options: [],
        isValid: false,
        on: true,
        fieldName: "nazioneRilascioField",
        salesforceFieldName: "Stato_di_Rilascio__c"
      },
      {
        label: "InserisciEnteRilascio",
        type: "text",
        required: true,
        value: "",
        valuename: "",
        settings: {},
        options: [],
        isValid: false,
        on: false,
        fieldName: "inserisciEnteRilascioField",
        salesforceFieldName: "Ente_Rilasciato__c"
      },
      {
        label: "Provincia del comune di rilascio*",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.documentoRic.provinciaRilascio",
        settings: {
          label2: "Scegliunaopzione"
        },
        options: [],
        isValid: false,
        on: false,
        fieldName: "provinciadelcomunedirilascioField",
        salesforceFieldName: ""
      },
      {
        label: "Comune di rilascio*",
        type: "fieldPickList",
        required: true,
        value: "",
        valuename: "user.documentoRic.comuneRilascio.Name",
        settings: {
          label2: "Scegliunaopzione",
          fallbackOption:"comuneNotFound"
        },
        options: [],
        isValid: false,
        on: false,
        fieldName: "comunedirilascioField",
        salesforceFieldName: "Comune_di_Rilascio__c"
      },
      {
        label: "Inserisci il comune manualmente",
        type: "text",
        required: true,
        value: "",
        valuename: "user.documentoRic.ente",
        isValid: false,
        on: false,
        fieldName: "inserisciilcomunemanualmenteField",
        salesforceFieldName: "Ente_Rilasciato__c"
      },
      {
        label: "CaricaDocumentoIdentita",
        type: "fieldFile",
        required: true,
        value: "",
        valuename: "user.fileInfoDocumentoIdentita",
        settings: {
          inSeparatedRow: true,
          maxMB:4.5
        },
        isValid: false,
        on: true,
        fieldName: "caricaDocumentoIdentitaField",
        salesforceFieldName: ""
      }
    ]
  },
  {
    step: 6,
    panel_title: ":)",
    fields: [
      {
        label: "thankYouForRegistering",
        type: "H1",
        required: false,
        isValid: true,
        value: "",
        valuename: "",
        on: true,
        fieldName: "thankYouForRegistering"
      }
    ]
  }
];

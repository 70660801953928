<template>
  <div class="home">
    <navbar :menulist="$store.state.mainmenu" ></navbar>

    <herobig :title="$t('title')"></herobig>

    <h2 class="welcome">{{ $t('subtitleCandidati') }}</h2>

    <boxcontainer>
      <div class="row">
        <tabs :tabs="tabs" @change="refresh">
          <template v-slot:candidatura>
            <h3 class="pt-1">{{ $t('StatoCandidatura') }} {{ nomecorso }}</h3>
            <p>{{ $t('CompletamentoIscrizione') }}</p>

            <icontabs :icons="status" @change="refresh">
              <template v-slot:candidato>
                <h3 class="py-1">{{ $t('subtitleCandidatura') }}</h3>
                <p>{{ $t('IdoneitàCandidato') }}</p>
                <h4>{{ messaggiofase['candidato'] }}</h4>                      
              </template>

              <template v-slot:documenti>
                <h3 class="py-1">{{ $t('Documenti') }}</h3>
                <p>{{ $t('StatoDocumenti') }}</p>
                <h4>{{ messaggiofase['documenti'] }}</h4>
              </template>

              <template v-slot:edizione>
                <h3 class="py-1">{{ $t('Edizione') }}</h3>
                <p>{{ $t('ConfermaEdizione') }}</p>
                <h4>{{ messaggiofase['edizione'] }}</h4>
              </template>

              <template v-slot:iscrizione>
                <h3 class="py-1">{{ $t('Iscrizione') }}</h3>
                <p>{{ $t('DocumentazioneIscrizione') }}</p>
                <h4>{{ messaggiofase['iscrizione'] }}</h4>
              </template>

              <template v-slot:pagamento>
                <h3 class="py-1">{{ $t('StatoPagamento') }}</h3>
                <p>{{ $t('StatoPagamenti') }}</p>
                <h4>{{ messaggiofase['pagamento'] }}</h4>
              </template>

              <template v-slot:completata>
                <h3 class="py-1">{{ $t('Completata') }}</h3>
                <p>{{ $t('ProcessoIscrizione') }}</p>
                <h4>{{ messaggiofase['completata'] }}</h4>
              </template>
            </icontabs>
          </template>

          <template v-slot:documenti>
            <h3>{{ $t('Documenti') }}</h3>
            <almatable :cols="cdocumenti" :rows="rdocumenti" @upload="uploadDocument($event)"></almatable>
          </template>

          <template v-slot:pagamenti>
            <h3>{{ $t('Pagamenti') }}</h3>
            <almatable :cols="cpagamenti" :rows="rpagamenti"></almatable>
          </template>

          <template v-slot:abbigliamento>
            <h3>{{ $t('Abbigliamento') }}</h3>
            <a v-if="$i18n.locale == 'it'" href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/7T0000001bbB/s2FOPeu9WvnOynniX1zjL4SDZgzPSQbF0ZhI7Y43g4A">
              <p style="color:#d29500">{{$t('guidaTaglie')}}</p>
            </a>
            <a v-if="$i18n.locale == 'en'" href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/7T0000001bbG/SNwpAvNLK.avxSEnn6EL2b.JBR1Dw.0zVIpvcc8qX9g">
              <p style="color:#d29500">{{$t('guidaTaglie')}}</p>
            </a>
            <div>
              <div class="col-xs-10 col-sm-10">
                <div class="row" v-for="a in abbigliamenti" :key="a.label">
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group" v-if="$store.state.candidatura[0] && $store.state.candidatura[0].Visibilit_abbigliamento__c && $store.state.candidatura[0].Abbigliamento_Compilato__c == false">
                      <label class="control-label">{{ $t(a.label) }}</label>
                      
                      <select
                        v-if="a.field == 'Taglia_Scarpe__c'" :value="a.value" class="form-control"
                        :title="$t('Scegliunaopzione')" @input="setField(a.field,$event)" :disabled="abbigliamentoDis"
                      >
                        <option v-for="n in numeri" :key="n" :value="n">{{ n }}</option>
                      </select>

                      <select v-if="a.field == 'Taglia_Bomber__c'" :value="a.value" class="form-control" :title="$t('Scegliunaopzione')"
                        @input="setField(a.field,$event)" :disabled="abbigliamentoDis">
                        <option  v-for="v in taglie_bomber" :key="v" :value="v">{{ v }}</option>
                      </select>

                      <select v-if="a.field == 'Taglia_Camicia__c'" :value="a.value" class="form-control" :title="$t('Scegliunaopzione')"
                        @input="setField(a.field,$event)" :disabled="abbigliamentoDis">
                        <option  v-for="v in taglie_camicia" :key="v" :value="v">{{ v }}</option>
                      </select>

                      <select v-if="a.field == 'Taglia_Giacca__c'" :value="a.value" class="form-control" :title="$t('Scegliunaopzione')"
                        @input="setField(a.field,$event)" :disabled="abbigliamentoDis">
                        <option  v-for="v in taglie_giacca" :key="v" :value="v">{{ v }}</option>
                      </select>

                      <select v-if="a.field == 'Taglia_Gilet__c'" :value="a.value" class="form-control" :title="$t('Scegliunaopzione')"
                        @input="setField(a.field,$event)" :disabled="abbigliamentoDis">
                        <option  v-for="v in taglie_gilet" :key="v" :value="v">{{ v }}</option>
                      </select>

                      <select v-if="a.field == 'Taglia_Pantaloni__c'" :value="a.value" class="form-control" :title="$t('Scegliunaopzione')"
                        @input="setField(a.field,$event)" :disabled="abbigliamentoDis">
                        <option  v-for="v in taglie_pantaloni" :key="v" :value="v">{{ v }}</option>
                      </select>

                      <select v-if="a.field == 'Taglia_Polo__c'" :value="a.value" class="form-control" :title="$t('Scegliunaopzione')"
                        @input="setField(a.field,$event)" :disabled="abbigliamentoDis">
                        <option  v-for="v in taglie_polo" :key="v" :value="v">{{ v }}</option>
                      </select>
                    </div>
                    
                    <div class="form-group" v-else>
                      <label class="control-label">{{ $t(a.label) }}</label>
                      <input class="form-control" :disabled="abbigliamentoDis" :value="a.value"/>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="$store.state.candidatura[0] && $store.state.candidatura[0].Visibilit_abbigliamento__c && $store.state.candidatura[0].Abbigliamento_Compilato__c == false">
                  <div class="col-sm-12 col-md-5"></div>
                
                  <div class="col-sm-12 col-md-5">
                    <button type="submit" class="btn btn-primary" :disabled="checkconferma" @click.prevent="saveAbb">
                      {{ $t('Conferma') }}
                    </button>
                    &nbsp;
                    <button type="submit" class="btn btn-outline-secondary" @click.prevent="savecancel()">
                      {{ $t('Annulla') }}
                    </button>
                  </div>
                </div>
              </div>  
            </div>
          </template>

          <template v-slot:visto>
            <visto></visto>
          </template>
        </tabs>
      </div>

      <modalupload
        :certkey="certkey" :tipoFile="cryptDoc ? 'cryptDoc' : undefined"
        @submit="uploadDone" @cancel="uploadCancel" :id="idDocument" :show="uploadShow"
      >
      </modalupload>
    </boxcontainer>

    <footerline></footerline>
  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar       from '@/components/navbar.vue'
  import herobig      from '@/components/herobig.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline   from '@/components/footerline.vue'
  import tabs         from '@/components/tabs.vue'
  import almatable    from '@/components/almatable.vue'
  import icontabs     from '@/components/icontabs.vue'
  import modalupload  from '@/components/modalupload.vue'
  import visto        from '@/components/visto.vue'

  export default {
    name: 'Candidato',
    components: {
      navbar,
      herobig,
      boxcontainer,
      footerline,
      tabs,
      almatable,
      icontabs,
      modalupload,
      visto,
    },
    computed: {
      nomecorso: function() {
        if (this.candidatura)
          if (this.candidatura.Edizione_Corso__r)
            return this.candidatura.Edizione_Corso__r.Name
            
        return ''
      },
      taglie_giacca: function() {
        return this.getPicklistValues('Opportunity','Taglia_Giacca__c');
      },
      taglie_bomber: function() {
        return this.getPicklistValues('Opportunity','Taglia_Bomber__c');
      },
      taglie_camicia: function() {
        return this.getPicklistValues('Opportunity','Taglia_Camicia__c');
      },
      taglie_gilet: function() {
        return this.getPicklistValues('Opportunity','Taglia_Gilet__c');
      },
      taglie_pantaloni: function() {
        return this.getPicklistValues('Opportunity','Taglia_Pantaloni__c');
      },
      taglie_polo: function() {
        return this.getPicklistValues('Opportunity','Taglia_Polo__c');
      },
      numeri: function() {
        return this.getPicklistValues('Opportunity','Taglia_Scarpe__c');
      },
      abbigliamentoDis: function() {
        if(!this.candidatura.Visibilit_abbigliamento__c || this.candidatura.Abbigliamento_Compilato__c || this.inviato) return true;
        return false;
      },
      checkconferma: function(){
        if(!this.candidatura.Visibilit_abbigliamento__c || this.candidatura.Abbigliamento_Compilato__c || this.inviato) return true;
        if(this.numAbbAdded > 0) return true;
        return false;
      },
    },
    data: function () {
      return {
        idDocument: '',
        inviato: false,
        uploadShow: false,
        candidatura: {},
        messaggiofase: {},
        tabs: [],
        status: [],
        cpagamenti: [],
        rpagamenti: [],
        //Documento	Da caricare entro il	Caricato il	Stato documento	Note	
        cdocumenti: [],
        rdocumenti: [],
        abbigliamenti: [], 

        cryptDoc: false,
        certkey: '',
        numAbbAdded: 0,
      };
    },
    mounted: function() {
      this.setTabs();
      this.refresh();
      this.sendpurchase();
    },
    methods: {
      sendpurchase:function(){
          console.log(this.$store.state.purchased)
        if(this.$store.state.purchased === true){
          this.$store.state.purchased = false;
          // eslint-disable-next-line no-undef
          gtag('event', 'purchase', { 'items': [this.$store.state.productcode, this.$store.state.course, this.$store.state.edition], 'send_to': 'GTM-KGMX463' });
          }
      },
      refresh:function() {
        console.log('--> refresh (Candidato.vue)')
        var self=this;

        console.log("dispatch('getVisto') in Candidato.vue")
        this.$store.dispatch('getVisto')

        console.log("dispatch('getCandidatura') in Candidato.vue")
        this.$store.dispatch('getCandidatura')
        .then(function() {
          return self.loadDocumenti();
        }).then(function() {
          return self.loadPagamenti();
        }).then(function() {
          self.statusIcons();
          self.getAbbigliamento();
        }).then(() => {
          return self.addTabVisto()
        })
      },
      setTabs:function() {
        this.tabs.push(
          { title:this.$i18n.t('Candidatura'), name:'candidatura'},
          { title:this.$i18n.t('Documenti'), name:'documenti'},
          { title:this.$i18n.t('Pagamenti'), name:'pagamenti'},
          { title:this.$i18n.t('Abbigliamento'), name:'abbigliamento'},
        )
        this.cpagamenti.push(
          { title:this.$i18n.t('Fattura'), name:'nfattura' },
          { title:this.$i18n.t('Importo'), name:'importo', filter:'currency' },
          { title:this.$i18n.t('Scadenza'),name:'scadenza', filter:'date' },
          { title:this.$i18n.t('Pagatail'), name:'pagamento', filter:'date' }
        )
        this.cdocumenti.push(
          // { title:'#', name:'ndocumento' },
          { title:this.$i18n.t('Tipo'), name:'documento' },
          { title:this.$i18n.t('Nome'), name:'nome' },
          { title:this.$i18n.t('Entro'), name:'entro', filter:'date' },
          { title:this.$i18n.t('Caricato'),name:'caricato', filter:'date' },
          { title:this.$i18n.t('Stato'), name:'stato' },
          { title:this.$i18n.t('Motivazione'), name:'motivo' },
          //{ title:'Note', name:'note' }
        )
      },
      addTabVisto() {
        console.log("dispatch('getVisto') in Candidato.vue")
        this.$store.dispatch('getVisto')
        .then(() => {
          if(this.$store.getters.enableVisto) {
            for(var t in this.tabs) {
              if(this.tabs[t].name == 'visto') return true
            }
            this.tabs.push({ title:this.$i18n.t('Visto'), name:'visto' })
            return true
          } else return false
        })
      },
      setField: function(f,e,t) {
        this.$store.commit('SET_USERPROFILE_DURTY', true );
        if (t!=undefined && t=='radio') {
          this.$store.commit('SET_CANDIDATURA_FIELD', { name: f, val: e} );
          return;
        }
        this.$store.commit('SET_CANDIDATURA_FIELD', { name: f, val: e.target.value} );

        for(var a of this.abbigliamenti) {
          if(a.field == f) {
            a.value = e.target.value
            this.numAbbAdded --
          }
        }
      },
      saveAbb: function() {
        this.inviato=true;
        var tmpOppty = this.candidatura;
        tmpOppty.Data_compilazione_Abbigliamento__c = Date.now();
        delete tmpOppty.attributes;
        console.log('funz save');
        console.log(tmpOppty);
        console.log("dispatch('updateCandidatura') in Candidato.vue")
        this.$store.dispatch('updateCandidatura', tmpOppty)
        .then(function(){
          alert('Taglie aggiornate correttamente!');
        });
      },
      savecancel: function() {
        this.getAbbigliamento();
      },
      getAbbigliamento: function(){
        console.log('getAbbigliamento in Candidato.vue');
        var self = this;
        const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) =>
          first.toLocaleUpperCase(locale) + rest.join('');
          console.log('getabbigliamento');
          console.log('this.candidatura',this.candidatura);
        console.log('this.candidatura.Codice_Corso__c',this.candidatura.Codice_Corso__c)
        var soql='select id, Codice_Corso__c, Sottofamiglia_Dotazione__c from Template_Corso_Dotazione__c where Codice_Corso__c=\''+this.candidatura.Codice_Corso__c+'\' and Richiede_Taglia__c = true order by Sottofamiglia_Dotazione__c asc';
        
        console.log("dispatch('getAbbigliamento') in Candidato.vue")
        this.$store.dispatch('getAbbigliamento', soql)
        .then(function(){
          console.log(self.$store.state.templatesAbbigliamento);
          self.abbigliamenti = [];
          self.numAbbAdded = 0
          self.$store.state.templatesAbbigliamento.forEach(function(t){
            var name = t.Sottofamiglia_Dotazione__c.toLowerCase();
            var abb = {}
            if(name =='pantalone'){
              abb = {label: 'Taglia'+capitalizeFirstLetter(name), field: 'Taglia_Pantaloni__c', value: self.candidatura['Taglia_Pantaloni__c']}
            // } else if(name =='bomber'){
            //   abb = {label: 'Taglia'+capitalizeFirstLetter(name), field: 'Taglia_Giacca__c', value: self.candidatura['Taglia_Giacca__c']}
            } else{
              abb = {label: 'Taglia'+capitalizeFirstLetter(name), field: 'Taglia_'+capitalizeFirstLetter(name)+'__c', value: self.candidatura['Taglia_'+capitalizeFirstLetter(name)+'__c']}
            }
            if(abb != {}) {
              self.abbigliamenti.push(abb)
              if(!abb.value) self.numAbbAdded ++
            }
          });

        console.log('ris abbigliamenti:');
        console.log(self.abbigliamenti);
        });
      },
      getPicklistValues: function(o,f) {
        if (this.$store.state.campi && this.$store.state.campi.has) {
          if (this.$store.state.campi.has(o)) {
            var af=this.$store.state.campi.get(o)
            if (af.has(f)) {
              var afv=af.get(f);
              if (afv) return afv.values.split(';');
            }
          }
        }
        return [];
      },
      uploadDocument: function(idDocument) {
        this.idDocument = idDocument
        this.rdocumenti.forEach(row => {
          if (row.Id === idDocument) {
            this.cryptDoc = row.isCrypt
            this.certkey  = row.certkey
          }
        })
        this.uploadShow = true
      },
      uploadDone: function() {
        this.uploadShow=false;        
        // need refresh
        this.loadDocumenti();
        this.statusIcons();
      },
      uploadCancel: function() {
        this.uploadShow=false;
      },
      loadPagamenti: function() {
        console.log('loadPagamenti in Candidato.vue')
        var self=this;
        return new Promise(function(resolve) {
          console.log("dispatch('getPagamenti') in Candidato.vue")
          self.$store.dispatch('getPagamenti').then(function() {
            console.log('getPagamenti!');
            self.rpagamenti.splice(0,self.rpagamenti.length);
            self.$store.state.pagamenti.forEach(function(r) {
              var d={ Id:r.Id, nfattura: r.Numero_partita__c, 
                importo: r.Importo_Emesso__c, 
                scadenza: r.Data_Scadenza__c, 
                pagamento:	r.Data_pagamento__c, 
                pagata: r.Pagata__c
              };
              self.rpagamenti.push(d);
            });
            resolve();
          });
        });
      },
      loadDocumenti: function() {
        return new Promise(resolve => {
          console.log("dispatch('getDocumenti') in Candidato.vue")
          this.$store.dispatch('getDocumenti').then(() => {
            console.log('getDocumenti!')

            this.rdocumenti.splice(0, this.rdocumenti.length)

            this.$store.state.documenti.forEach((r, i) => {
              var d = {
                Id:         r.Id,
                ndocumento: i + 1,
                documento:  r.Tipo_Documento__c,
                nome:       r.Name,
                entro:      r.Da_caricare_entro__c,
                caricato: 	r.Data_Caricamento__c,
                stato:      r.Stato__c,
                note:       r.Note__c,
                motivo:     r.Causale_Rifiuto__c,

                isCrypt: r.Documento_Crittato__c,
                certkey: r.Certificato_Digitale__r ? r.Certificato_Digitale__r.Chiave_Pubblica__c : ''
              }

              if(r.Tipo_Documento__c.includes('_')) d.documento = r.Tipo_Documento__c.slice(r.Tipo_Documento__c.indexOf('_')+1)

              if (r.Stato__c == 'Richiesto') d.upload = true

              this.rdocumenti.push(d)
            })

            console.log('rdocumenti', this.rdocumenti)
            
            resolve()
          })
        })
      },
      statusIcons: function() {
        if (!this.$store.state.candidatura) return;
        if (this.$store.state.candidatura.length<1) return;

        let filteredCandidature = this.$store.state.candidatura.filter(function(candidatura) {
          return candidatura.IsActive__c;
        })

        if(filteredCandidature.length < 1) return;

        this.candidatura=filteredCandidature[0];

        var fase=this.candidatura.StageName;
        console.log('fase');        
        console.log(fase);        
/*
Raccolta documenti idoneità
In conferma idoneità
Raccolta documenti iscizione
In attesa conferma pagamento
Iscrizione da completare
Accettato
Lista d'Attesa
Iscrizione Completata
Candidato Ritirato
Rifiutato
*/
        /*
            1 Omino = candidato  (Rosso= non valido,  Giallo= in validazione, Verde= validato)
            2 X Documenti (Giallo = mancano, Verde = ok)
            3 Edizione = calendario (Giallo= da confermare, Verde = confermata, Wait = Gialla)
            4 Iscrizione = penna? (Grigio = aspetta, Giallo = da fare, Verde = ok)
            5 X Pagamenti = euro ( Grigio = aspetta , Giallo = da fare , Verde = ok, Rosso? = ko)
            6 OK / NO = spunta / divieto (Grigio = n/a, Verde = OK, Rosso = KO )

            1 Omino = candidato  (Rosso= non valido,  Giallo= in validazione, Verde= validato)
            2 Edizione = calendario (Giallo= da confermare, Verde = confermata, Wait = Gialla)
            3 X Documenti (Giallo = mancano, Verde = ok)
            4 X Pagamenti = euro ( Grigio = aspetta , Giallo = da fare , Verde = ok, Rosso? = ko)
            5 Iscrizione = penna? (Grigio = aspetta, Giallo = da fare, Verde = ok)
            6 OK / NO = spunta / divieto (Grigio = n/a, Verde = OK, Rosso = KO )
        */

        this.status=[];

        var statoGenerale='grey';        
        switch (this.candidatura.statoGenerale__c) {
          case 'non valido':
            this.messaggiofase['candidato']=this.$i18n.t('rossoCandidatura');
            statoGenerale='red';
          break;
          case 'in validazione':
            this.messaggiofase['candidato']=this.$i18n.t('gialloCandidatura');
            statoGenerale='yellow';
          break;
          case 'validato':
            this.messaggiofase['candidato']=this.$i18n.t('verdeCandidatura');
            statoGenerale='green';
          break;
        }
        var sg={id:1, name:'candidato', title: 'Candidato:'+this.candidatura.statoGenerale__c, iconname: 'person', color: statoGenerale };
        this.status.push(sg);


        var statoEdizione='grey';        
        var statoEdizioneIcon='calendar';   
        this.messaggiofase['edizione']=this.$i18n.t('grigioEdizione');
        
        switch (this.candidatura.statoEdizione__c) {
          case 'da confermare':
            this.messaggiofase['edizione']=this.$i18n.t('gialloEdizione');
            statoEdizione='yellow';
          break;
          case 'confermata':
            this.messaggiofase['edizione']=this.$i18n.t('verdeEdizione');
            statoEdizione='green';
          break;
          case 'wait':
            this.messaggiofase['edizione']=this.$i18n.t('gialloLista');
            statoEdizione='yellow';
            statoEdizioneIcon='timer'
          break;
        }
        var se={id:2, name:'edizione', title: 'Edizione:'+this.candidatura.statoEdizione__c, iconname: statoEdizioneIcon, color: statoEdizione };
        this.status.push(se);



        this.messaggiofase['documenti']=this.$i18n.t('grigioDocumenti');
        var statoDocumenti='grey';
        var docs=this.$store.state.documenti;
        if (docs.length>0) {
          statoDocumenti='green';
          this.messaggiofase['documenti']=this.$i18n.t('verdeDocumenti');
          docs.forEach(function(d) {
            if (d.Stato__c=='Richiesto' || d.Stato__c=='In attesa di validazione' ) { 
              statoDocumenti='yellow';
              this.messaggiofase['documenti']=this.$i18n.t('gialloDocumenti');
            }
          },this);
        }
        var sd={id:3, name:'documenti', title: 'Documenti', iconname: 'folder', color: statoDocumenti };
        this.status.push(sd);



        var statoPagamento='grey';
        this.messaggiofase['pagamento']=this.$i18n.t('grigioPagamento');
        var pays=this.$store.state.pagamenti;
        if (pays.length>0) {
          statoPagamento='green';
          this.messaggiofase['pagamento']=this.$i18n.t('verdePagEff');
          pays.forEach(function(d) {
            if (!d.Data_pagamento__c) { 
              statoPagamento='yellow'; // add red situation
              this.messaggiofase['pagamento']=this.$i18n.t('gialloPagamento');
            }
          },this);
        }

        if (statoGenerale=='green' && pays.length==0) {
          if (this.candidatura.Pagamenti_in_regola__c) {
            statoPagamento='green';
            this.messaggiofase['pagamento']=this.$i18n.t('verdePagEff');
          } else {
              statoPagamento='yellow'; // add red situation
              this.messaggiofase['pagamento']=this.$i18n.t('gialloPagamento');
          }
        }

        var sp={id:4, name:'pagamento', title: 'Pagamento', iconname: 'euro', color: statoPagamento };
        this.status.push(sp);


        var statoIscrizione='grey';   
        this.messaggiofase['iscrizione']=this.$i18n.t('grigioIscrizione');
        switch (this.candidatura.statoIscrizione__c) {
          case 'attendere':
            statoIscrizione='grey';
            this.messaggiofase['iscrizione']=this.$i18n.t('grigioIscrizione');
          break;
          case 'da fare':
            statoIscrizione='yellow';
            this.messaggiofase['iscrizione']=this.$i18n.t('gialloIscrizione');
          break;
          case 'ok':
            statoIscrizione='green';
            this.messaggiofase['iscrizione']=this.$i18n.t('verdeIscrizione');
          break;
        }
        var si={id:5, name:'iscrizione', title: 'Iscrizione:'+this.candidatura.statoIscrizione__c, iconname: 'pencil', color: statoIscrizione };
        this.status.push(si);


        var statoCompletata='grey';
        var statoCompletataIcon='check';
        var statoCompletataTitle;
        statoCompletataTitle=this.$i18n.t('InCorso');
        this.messaggiofase['completata']=this.$i18n.t('grigio');
            
        switch (this.candidatura.statoCompletata__c) {
          case 'n/a':
            statoCompletata='grey';
            this.messaggiofase['completata']=this.$i18n.t('grigio');
          break;
          case 'ko':
            statoCompletata='red';
            statoCompletataIcon='bar';
            statoCompletataTitle=this.$i18n.t('NonAccettata');
            this.messaggiofase['completata']=this.$i18n.t('rosso');
            
          break;
          case 'ok':
            statoCompletata='green';
            statoCompletataTitle=this.$i18n.t('Completata');
            this.messaggiofase['completata']=this.$i18n.t('verde');
            
          break;
        }
        var sna={id:6, name:'completata', title: statoCompletataTitle, iconname: statoCompletataIcon, color: statoCompletata};
        this.status.push(sna);

      },
      // getVisto() {
      //   return new Promise(resolve => {
      //     console.log("dispatch('getVisto') in Candidato.vue")
      //     this.$store.dispatch('getVisto')
      //     .then(() => {
      //       resolve()
      //     })
      //   })
      // },
    }
  }
</script>
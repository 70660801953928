<template>
  <div v-if="type === 'filler'"></div>

  <div v-else-if="type === 'checkbox'" style="margin-left: 23px" class="form-group paddingpiu custom-checkbox">
    <input type="checkbox" class="custom-control-input" id="customCheckbox" :checked="fieldValue" @change="notifyParentFromCheckbox">
    <label for="customCheckbox" class="custom-control-label">
      <span style="font-weight: bold; margin: 8px; color: red;">*</span>
      {{ placeholder }}
      <a v-if="settings.withFileLink" :href="fileInfoPrivacy()" target="blank_">
        <!-- TODO it isnt translated it just name of variable -->
        <b>{{ settings.fileLabel }}</b>
      </a>
    </label>
  </div>

  <div v-else-if="type === 'fieldRadioButtons'">
    <fieldRadioButtons :isValid="isValid" :settings="settings" :label="label" :translatedLabel="placeholder"
      :translatedLabelYES="$t(settings.labelYES)" :translatedLabelNO="$t(settings.labelNO)" :required="required" :fieldValue="fieldValue" @changed="notifyParent">
    </fieldRadioButtons>
  </div>
<!--  -->
  <div v-else-if="type === 'tel' || type === 'email' || type === 'text' || type === 'date' || type === 'PASSWORD'">
    <div class="form-group">
      <label class="control-label">{{ placeholder }}</label>
      <div class="input-group">
        <input :class="required ? (isValid ? 'is-valid' : 'is-invalid') : ''" maxlength="100"
          v-bind:type="reveal ? 'text' : type" :value="fieldValue"  v-bind:disabled="settings?.isDisabled" class="form-control" :placeholder="placeholder" @input="notifyParent"
          @keypress="type === 'tel' ? isNumber($event) : null"  />
        <!-- TODO reveal -->
        <div v-if="type === 'PASSWORD' && settings.showRevealButton" class="input-group-append" v-on:click="chgReveal">
          <span class="input-group-text">
            <span v-if="!reveal" class="oi oi-eye"></span>
            <span v-if="reveal" class="oi oi-code"></span>
          </span>
        </div>
      </div>

    </div>
  </div>
  <div v-else-if="type === 'fieldPickList'">
    <fieldPickList :isValid="isValid" :label="label" :fieldKey="fieldKey" :settings="settings" :options="options" :placeholder="placeholder"
      :translatedLabel2="translatedLabel2" :required="required" :fieldValue="fieldValue" @changed="notifyParent"> </fieldPickList>
  </div>
  <div v-else-if="type === 'h4'">
    <h4 style="margin-top: 20px">{{placeholder}}</h4>
  </div>
  <div v-else-if="type === 'fieldFile'" :class="settings.inSeparatedRow ? ['col-sm-11', 'col-md-12'] : ''">
    <fieldFile :label="label" :placeholder="placeholder" :isValid="isValid" :settings="settings" :required="required" @changed="notifyParentFromFile"></fieldFile>
  </div>
  <div v-else-if="type === 'H1'">
    <h1>thank you for registering</h1>
  </div>
  

  <div v-else>
    L: {{ label }}
    T: {{ type }}
    IV: {{ isValid }}
    O: {{ settings }}
    R: {{ required }}
  </div>
</template>

<script>
// @ is an alias to /src
import fieldRadioButtons from '@/components/fieldRadioButtons.vue'
import fieldPickList from '@/components/fieldPickList.vue'
import fieldFile from '@/components/fieldFile.vue';


export default {
  name: 'field',
  components: {
    fieldRadioButtons,
    fieldPickList,
    fieldFile
  },
  props: {
    fieldKey:String,
    type: String,
    label: String,
    translatedLabel2:String,
    required: Boolean,
    isValid: Boolean,
    placeholder: String,
    settings: Object,
    options:Array,
    fieldValue:[String, Boolean,Number],
  },
  data: function () {
    return {
      reveal: false,
    };
  },
  mounted: function () { },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[+][0-9]+|^[0-9]+/.test(char)) return true;
      else e.preventDefault();
    },
    chgReveal: function () {
      this.reveal = !this.reveal;
    },
    fileInfoPrivacy() {
      if (this.$i18n.locale == 'it') return 'https://www.alma.scuolacucina.it/privacy-policy/'
      if (this.$i18n.locale == 'en') return 'https://www.alma.scuolacucina.it/privacy-policy/'
      return ''
    },
    
    notifyParent(event) {
      this.$emit('changed', event.target.value);
    },
    notifyParentFromCheckbox(event) {
      this.$emit('changed', event.target.checked);
    },
    notifyParentFromFile(event) {
      this.$emit('changed', event);
    },

  }
}
</script>

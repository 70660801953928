import Vue from 'vue'
import Vuex from 'vuex'
import apiclient from '@/apiclient.js'
import authClientLib from '@/authClientLib.js'

import jwt from 'jsonwebtoken'

// import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)


export default new Vuex.Store({
  // plugins: [
  //   createPersistedState({
  //     storage: {
  //       getItem: (key) => ls.get(key),
  //       setItem: (key, value) => ls.set(key, value),
  //       removeItem: (key) => ls.remove(key),
  //     },
  //   }),
  // ],
  state: {
    lingua: 'it',
    routerlock: false,
    mainmenu: [],
    durty: false,
    userprofile: {},

    idOppty: '',
    idOpptyTesi: '',
    opptyCorso: '',
    idHousing: '',
    opptyHousing: '',
    opptyHousingFatt: {},
    opptyPrenot: {},
    housing: [],
    templatesAbbigliamento: {},

    connection: {
      commit: {},
      username: '',
      token: '',
      userdata: '',
    },
    campi: {},
    picklistReady:false,
    prodotti: {},

    casi: {},
    casicat: [],

    vouchercode: '',

    // ecommerce
    imgEcommerce: {},
    categoriaEcommerce: [],
    carrelloEcommerce: [],
    totaleEcommerce: 0,

    tesi: {},
    piatto: {},
    condizione: {},

    corsi: {},
    materiali: {},
    candidatura: {},
    visto: {},
    documenti: {},
    pagamenti: {},
    emailRecovery: '',
    tkn: '',
    newpwd: '',
    newpwdconf: '',
    fakelogin: false,
    fakeloginstatus: false,
    blocked: false,
    registrationDenied: false,
    productcode: '',
    course: '',
    edition: '',
    purchased: false,

    righeOrdineEcommerce: [],

    allProds: [],
    shoppers: [],
    step: 0,
    isProviderLogin:false,

    prefissiTelefono: [],
  },
  getters: {
    logged: function (state) {
      if (state.fakelogin) return state.fakeloginstatus;
      return (state.connection.userdata && state.userprofile.Username__pc ? true : false)
    },
    isloggedNew: function (state){
      return (state.connection.userdata && (state.userprofile.Username__pc || state.userprofile.PersonEmail) ? true : false)
    },
    isProviderLogin: function (state){
      return state.isProviderLogin
    },
    getproductcode: function (state) {
      return state.productcode
    },
    getstep: function (state) {
      return state.step
    },
    getuserFinished: function (state) {
      return state.userFinished
    },
    housing: function (state) {
      var f = false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function (o) {
            if (o.IsActiveHousing__c) f = true;
          });
        }
      }
      return f;
    },
    candidato: function (state) {
      if (state.userprofile)
        if (state.userprofile.Categoria__c)
          return state.userprofile.Categoria__c.includes('Candidato');
    },
    studente: function (state) {
      if (state.userprofile)
        if (state.userprofile.Categoria__c)
          return state.userprofile.Categoria__c.includes('Studente');
    },
    dipendente: function (state) {
      if (state.userprofile)
        if (state.userprofile.Categoria__c)
          return state.userprofile.Categoria__c.includes('Dipendente')
    },
    isEcommerceVisible: function (state) {
      // if (state.userprofile)
      // if (state.userprofile.Id === '0010Q00001D4sDFQAZ' || state.userprofile.Id === '0015J000008bYR2QAM' || state.userprofile.Id === '0010Q00001X0AmtQAF' || state.userprofile.Id === '0015J000005xtNpQAI')
      // if (state.userprofile.isEcommerce__c == true) return true
      if (state.userprofile.isEcommerce__c) {
        console.log('controllato isEcommerce__c', state.userprofile.isEcommerce__c)
        return true
      }
      if (state.opptyCorso && state.opptyCorso.isActiveEcommerce__c) return true

      // 0010Q00001D4sDFQAZ => test modea     (sandbox)
      // 0010Q00001X0AmtQAF => account dummy  (sandbox)
      // 0015J000008bYR2QAM => gabriele prova (prod)
      // 0015J000005xtNpQAI => ilaria         (prod)

      return false
    },
    isRicaricaVisible: function (state) {
      if (state.opptyCorso.isRicarica__c) return true
      // if (state.userprofile.idTesseraBar__c && state.userprofile.idTesseraBar__c != '') return true
      return false
    },
    hyperplan: function (state) {
      var f = false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function (o) {
            if (o.isActiveHyperplanning__c) f = true;
          });
        }
      }
      return f;
    },
    stage: function (state) {
      var f = false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function (o) {
            if (o.IsActiveStage__c) f = true;
          });
        }
      }
      return f;
    },
    enablePresenze: function (state) {
      var f = false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function (o) {
            if (o.IsActivePresenze__c) f = true;
          });
        }
      }
      return f;
    },
    matricola: function (state) {
      var f = false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function (o) {
            console.log(o.Name + ' ' + o.IsActiveTesi__c);
            if (o.IsActiveTesi__c) f = true;
          });
        }
      }
      return f;
    },
    tesi: function (state) {
      console.log('getters tesi');
      var f = false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function(o) {
            console.log(o.Name+' tesi='+o.IsActiveTesi__c);
            if (o.IsActiveTesi__c || o.IsActiveTesiIntermedia__c){
              f=true;
              state.idOpptyTesi = o.Id;
            }
          })
        }
      }
      return f;
    },
    getOpptyTesi (state) {
      var tesi = {}
      if (state.userprofile && state.userprofile.Opportunities) {
        state.userprofile.Opportunities.records.forEach( o => {
          if (o.IsActiveTesi__c || o.IsActiveTesiIntermedia__c) tesi = o
        })
      }
      return tesi
    },
    elearn: function(state) {
      var f=false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function (o) {
            console.log(o.Name + ' elearning=' + o.isActiveElearning__c);
            if (o.isActiveElearning__c) f = true;
          });
        }
      }
      return f;
    },
    diplomato: function (state) {
      if (state.userprofile)
        if (state.userprofile.Categoria__c)
          return state.userprofile.Categoria__c.includes('Diplomato');
    },
    prenotazioni: function (state) {
      var f = false;
      if (state.userprofile) {
        if (state.userprofile.Opportunities) {
          state.userprofile.Opportunities.records.forEach(function (o) {
            console.log(o.Name + ' prenot=' + o.IsActivePrenotazione__c);
            if (o.IsActivePrenotazione__c) {
              f = true;
              state.opptyPrenot = o;
            }
          });
        }
      }
      return f;
    },
    enableEsami: function (state) {
      if (state.opptyPrenot && state.opptyPrenot.hasExams__c) return true
      return false
    },
    enableVisto: function (state) {
      if (state.visto != undefined) return true
      return false
    },
    allProds: function(state){
      return state.allProds;
    },
    shoppers: function(state){
      return state.shoppers;
    }
  },
  mutations: {
    SET_LINGUA: function (state, payload) { state.lingua = payload; },
    SET_ROUTERLOCK: function (state, payload) { state.routerlock = payload; },
    SET_CAMPI: function (state, payload) { state.campi = payload; },
    SET_PRODOTTI: function (state, payload) { state.prodotti = payload; },
    
    SET_ISPROVIDERLOGIN: function (state, payload) { state.isProviderLogin = payload; },
    SET_STEP: function (state, payload) { state.step = payload.step; },
    SET_PRODUCTCODE: function (state,payload){state.productcode = payload.productcode},
    SET_COURSE: function (state,payload){state.course = payload.course},
    SET_EDITION: function (state,payload){state.edition = payload.edition},
    SET_PICKLISTREADY: function (state,payload){state.picklistReady = payload},

    SET_FAKELOGIN: function (state, payload) { state.fakelogin = true; state.fakeloginstatus = payload; },

    SET_CASI: function (state, payload) { state.casi = payload; },
    SET_CATEGORIE_CASI: function (state, payload) { state.casicat = payload },

    SET_TESI: function(state, payload) { 
      if(!payload && state.tesi.Id == undefined) state.tesi = ls.get('tesi')
      else {
        state.tesi = payload; 
        ls.set('tesi', state.tesi);
      }
    },
    SET_CONDIZIONE: function (state, payload) { state.condizione = payload; },
    SET_PIATTO: function (state, payload) { state.piatto = payload; },

    SET_LIBRI: function (state, payload) { state.libri = payload; },

    SET_CORSI: function (state, payload) { state.corsi = payload; },
    SET_MATERIALI: function (state, payload) { state.materiali = payload; },

    SET_HOUSING: function (state, payload) { state.housing = payload; },
    SET_CANDIDATURA: function (state, payload) { state.candidatura = payload; },
    SET_VISTO: function (state, payload) { state.visto = payload; },
    SET_DOCUMENTI: function (state, payload) { state.documenti = payload; },
    SET_PAGAMENTI: function (state, payload) { state.pagamenti = payload; },

    SET_PURCHASED: function (state, payload) { state.purchased = payload; },
    SET_MAINMENU: function (state, payload) { state.mainmenu = payload; },

    // connessione crittata con il backend
    SET_CONNECTION: function (state, payload) { state.connection = payload; },
    SET_BLOCKED: function (state, payload) { state.blocked = payload; },
    SET_REG_DENIED: function (state, payload) { state.registrationDenied = payload; },
    // profilo utente
    SET_USER_PROFILE: function (state, payload) { state.userprofile = payload; state.durty = false; },
    GET_USER_PROFILE: function (state) { let tmp = localStorage.getItem('userprofile'); tmp = jwt.decode(tmp); state.userprofile = tmp },
    SET_USERPROFILE_DURTY: function (state, payload) { state.durty = payload; },
    SET_USERPROFILE_FIELD: function (state, payload) { state.userprofile[payload.name] = payload.val; },
    //update campi opportunità
    SET_CANDIDATURA_FIELD: function (state, payload) { state.candidatura[0][payload.name] = payload.val; },
    SET_ABBIGLIAMENTO_CANDIDATURA: function (state, payload) { state.templatesAbbigliamento = payload; },
    SET_ORDINI_ECOMMERCE: function (state, payload) { state.righeOrdineEcommerce = payload; },
    SET_CATEGORIA_ECOMMERCE: function (state, payload) { state.categoriaEcommerce = payload; },
    // retrocompabilita' con il precedente portale: localStorage e cookies
    RESET_USER_DATA: function (state) {
      console.log('RESET_USER_DATA');
      let storageVariables = ['dataCarrello', 'nome', 'nomeGiurato', 'tavoloGiurato', 'tesi', 'user', 'userprofile']
      for (let sv of storageVariables) {
        if (localStorage.getItem(sv)) localStorage.removeItem(sv)
      }
      // localStorage.removeItem('user');
      //localStorage.clear();
      state.connection.username = '';
      state.connection.userdata = '';
      state.connection.status = '';
      state.connection.token = '';
      state.userprofile = {};
      state.opptyCorso = {}
      state.carrelloEcommerce = [];
    },
    GET_USER_DATA: function (state) {
      console.log('GET_USER_DATA');
      var userData = localStorage.getItem('user');
      console.log(userData);
      if (userData) {
        var ud = JSON.parse(userData);
        state.connection.username = ud.username;
        state.connection.userdata = ud.token;
        state.connection.status = ud.status;
        state.login = true;

        if (window.OpenReplay && window.OpenReplay.setUserID) {
          console.log('setuserid');
          window.OpenReplay.setUserID(ud.username);
        }

        console.log('localStorage', localStorage)
        var jwtpayload = localStorage.getItem('userprofile');
        if (jwtpayload) {
          jwt.verify(jwtpayload, apiclient.secret, function (err, data) {
            if (err) {
              console.log('bad request: jwt broken');
              return;
            } else {
              state.userprofile = data;
              console.log('state.userprofile', data)
              if (state.userprofile.Opportunities) {
                console.log('Opportunities');
                console.log(state.userprofile.Opportunities);
                var opptyPorte_Ecommerce = ''
                var opptyPorte_o_Ecommerce = ''
                state.userprofile.Opportunities.records.forEach(function (o) {
                  //console.log(o);
                  if (o.IsActive__c && !o.IsActiveHousing__c && o.Name.indexOf('Housing') < 0 && o.Name.indexOf('eCommerce') < 0) {
                    console.log('oppty: ', o);
                    state.opptyCorso = o;
                    state.idOppty = o.Id;
                  } else if (o.IsActiveAperturaPorte__c && o.isActiveEcommerce__c && o.Name.indexOf('Housing') < 0 && o.Name.indexOf('eCommerce') < 0) {
                    opptyPorte_Ecommerce = o;
                  } else if ((o.IsActiveAperturaPorte__c || o.isActiveEcommerce__c) && o.Name.indexOf('Housing') < 0 && o.Name.indexOf('eCommerce') < 0) {
                    opptyPorte_o_Ecommerce = o;
                  }
                  if (o.IsActiveHousing__c) {
                    console.log('housing');
                    state.opptyHousing = o;
                    state.idHousing = o.Id;
                  }
                  if (o.Housing_compilazione_dati__c) {
                    state.opptyHousingFatt = o;
                  }
                  if (state.opptyHousingFatt && o.IsActiveHousing__c) {
                    state.opptyHousingFatt = o;
                  }
                });
                // se tutte le oppty sono isActive = false, ma serve apriporte o ecommerce
                if (state.opptyCorso == '') {
                  if (opptyPorte_Ecommerce != '') state.opptyCorso = opptyPorte_Ecommerce
                  else if (opptyPorte_o_Ecommerce != '') state.opptyCorso = opptyPorte_o_Ecommerce
                }
              }
            }
          });
        }
      }
    },
    SET_USER_DATA: function (state, userData) {
      console.log('SET_USER_DATA');

      localStorage.setItem('user', JSON.stringify(userData));

      var exp = new Date();
      var minutes = 1440;
      exp.setTime(exp.getTime() + (minutes * 60 * 1000));

      var myObject = { "almauser": userData.username, "token": userData.token };
      document.cookie = 'almauser=' + JSON.stringify(myObject) + ';expires=' + exp + ';domain=scuolacucina.it;path=/';

      var numeroNotifiche = 0;
      var myObject2 = { "numeronotifiche ": numeroNotifiche };
      document.cookie = 'almacart=' + JSON.stringify(myObject2) + ';expires=' + exp + ';domain=scuolacucina.it;path=/';

      // console.log(document.cookie);
    },
    // ecommerce: carrello
    GET_CARRELLO: function (state) {
      console.log('GET_CARRELLO')
      var carrello = localStorage.getItem('carrello');

      if (carrello) {
        console.log('è passata meno di una settimana')
        state.carrelloEcommerce = JSON.parse(carrello);
        var tot = 0;
        state.carrelloEcommerce.forEach(function(d){
          tot+=parseInt(d.qty)* (d.Prezzo__c ? d.Prezzo__c : 0);
        })
        state.totaleEcommerce = tot.toFixed(2);
      }
    },
    SET_CARRELLO: function (state, data) {
      console.log('SET_CARRELLO: ', data);
      console.log('dimensione carrello', JSON.stringify(data).length)

      try {
        localStorage.setItem('carrello', JSON.stringify(data));
      } catch (err) {
        console.log(err)
        for (let d of data) {
          d.img = '/img/apple-touch-icon.png'
        }
        console.log('dimensione carrello senza immagini', JSON.stringify(data).length)
        localStorage.setItem('carrello', JSON.stringify(data));
      }
      // localStorage.setItem('carrello', JSON.stringify(data));

      var exp = new Date();
      var minutes = 1440;
      exp.setTime(exp.getTime() + (minutes * 60 * 1000));

      document.cookie = 'almacarrello=' + JSON.stringify(data) + ';expires=' + exp + ';domain=scuolacucina.it;path=/';
      // console.log(document.cookie);
    },
    REMOVE_PROD_CARRELLO: function (state, userData) {
      console.log('REMOVE_PROD_CARRELLO');
      state.carrelloEcommerce.splice(userData, 1);
      var tot = 0;
      state.carrelloEcommerce.forEach(function(d){
        tot+=parseInt(d.qty)* (d.Prezzo__c ? d.Prezzo__c : 0);
      })
      state.totaleEcommerce = tot;
      localStorage.setItem('carrello', JSON.stringify(state.carrelloEcommerce));
    },
    RESET_CARRELLO: function (state) {
      console.log('RESET_CARRELLO');
      localStorage.removeItem('carrello');
      state.carrelloEcommerce = [];
    },
    // ecommerce: immagini
    SET_IMAGE: function (state, id, img) {
      console.log(`SET_IMAGE id: ${id}`)
      state.imgEcommerce.id = img
    },
    SET_FIELD_TESI: function (state, payload) {
      console.log('SET_FIELD_TESI', payload)
      state.tesi[payload.field] = payload.value
    },
    SET_PRODS: function(state, payload) {
      console.log('SET_PRODS', payload)
      state.allProds = payload;
    },
    SET_SHOPPERS: function(state, payload) {
      console.log('SET_SHOPPERS', payload)
      state.shoppers = payload;
    },
    SET_PREFISSI_TELEFONO: function (state, payload) { state.prefissiTelefono = payload.records },
    CLEAR_PREFISSI_TELEFONO: function (state) { state.prefissiTelefono = [] }
  },
  actions: {
    getProdsEcommerce: async function(context, payload) {
      console.log('SET_ARTICOLI_ECOMM', payload)
      let cat = await context.dispatch('prezzoCategoria');
      console.log('categoria', cat);
      let resp;
      if(payload)
        resp = await context.dispatch('getProdEcommerce', payload);
      else
        resp = await context.dispatch('getProdEcommerce', {ricerca: '', filtro: '', crit: ''});
      
      let prods = [];
      let shoppers = [];
      if (resp) {
        console.log('prodotti resp: ', resp)

        var abProds = {}
        var prodConRicamo = {}

        for(var d of resp) {
        // resp.forEach((d, /*i*/) => {
            // if(d.Tempo_approvvigionamento__c) console.log('id: '+d.Id+', name: '+d.Name+', temApp: '+d.Tempo_approvvigionamento__c)
            //if (d.ContentDocumentLinks) d.ContentDocumentLinks.records.forEach(r => ids.push(r.ContentDocumentId))
            if(d.Family == 'Shopper') {
                d.Prezzo__c = d[`Prezzo_${cat}__c`]
                shoppers.push(d);
                continue
            }

            if(d.Family == 'Ricamo') {
                d.Prezzo__c = d[`Prezzo_${cat}__c`]
                // this.ricamo = d
                continue
            }

            d.statoDispo = d.Quantita_Disponibile__c > 0 ? 'In Stock' : 'Prenotabile'

            switch (d.Family) {
                case 'Libri':
                    d.showLingua = true
                    break;
                case 'Torta':
                    d.showDetCibo = true // allergeni?
                    d.statoDispo = d.Quantita_Disponibile__c > 0 ? 'In Stock': 'Esaurito'
                    break;
                case 'Abbigliamento':
                    //d.showTaglie = true
                    d.scarpa = d.Description.includes('SCARP') ? true : false // ?
                    break;
            }

            var c = d.Codici_Corso__c
            if (c) d.corsi = c.split(';')

            d.LastModifiedDate = new Date(d.LastModifiedDate)
            // d.img = undefined

            d.Attachments ? d.hasImage = true : d.hasImage = false

            //d.page = Math.ceil((i + 1) / 6)

            d.Prezzo__c = d[`Prezzo_${cat}__c`]

            if (d.Family === 'Abbigliamento') {
                var name = ''
                if(d.Note__c && d.Note__c != '') name = d.Note__c
                else name = d.Name

                if(d.Possibilita_Ricamo__c) prodConRicamo[name] = d.Possibilita_Ricamo__c

                if (Object.keys(abProds).includes(name)) {
                    abProds[name].variants.push(d)
                    abProds[name].mTaglieVarianti[d.Sesso_Taglia__c.substring(1)] = d
                } else {
                    abProds[name] = {
                        Id: d.Id,
                        variants: [d],
                        taglie: [],
                        scarpa: d.scarpa,
                        mTaglieVarianti : {},
                        LastModifiedDate: new Date(1990, 1, 1),
                        hasImage: false,
                        Codice_Aliquota__c: d.Codice_Aliquota__c,
                        Regime_Iva__c: d.Regime_Iva__c,
                        Tempo_approvvigionamento__c: d.Tempo_approvvigionamento__c
                    }
                    abProds[name].mTaglieVarianti[d.Sesso_Taglia__c.substring(1)] = d
                }
            } else prods.push(d);
        }
        console.log('prodotti: ', resp)
        
        for (let p of Object.values(abProds)) {
            // console.log('p e i',p,i)
            p.variants.forEach(v => {
                if(v.Sesso_Taglia__c != '**') p.taglie.push(v.Sesso_Taglia__c.substring(1))

                var tmpDate = new Date(v.LastModifiedDate)
                if (tmpDate > p.LastModifiedDate) p.LastModifiedDate = tmpDate

                if (v.hasImage) {
                    p.Id = v.Id
                    p.hasImage = true
                }
                if (v.Codice_Aliquota__c) p.Codice_Aliquota__c = v.Codice_Aliquota__c
                if (v.Regime_Iva__c) p.Regime_Iva__c = v.Regime_Iva__c
            })

            if(p.scarpa) {
                p.taglie = p.taglie.sort(function(a, b){return a - b})
                console.log('p.taglie',p.taglie)
            }else p.taglie = await context.dispatch('sortTaglie', p.taglie);

            var nameV = ''
            if(p.variants[0].Note__c && p.variants[0].Note__c != '') nameV = p.variants[0].Note__c
            else nameV = p.variants[0].Name

            var prod = {
                ProductCode: p.variants[0].ProductCode,
                // ProductCode : `AB${i}`,
                Id: p.Id,
                Family: 'Abbigliamento',
                LastModifiedDate: p.LastModifiedDate,
                hasImage: p.hasImage,
                // campi presi dalla prima variante
                // Name: p.variants[0].Name,
                Name: nameV,
                Description: p.variants[0].Description,
                Prezzo__c: p.variants[0][`Prezzo_${cat}__c`],
                corsi: p.variants[0].corsi,
                scarpa: p.variants[0].scarpa,
                // taglie e varianti
                taglie: p.taglie,
                variants: p.variants,
                Codice_Aliquota__c: p.Codice_Aliquota__c,
                Regime_Iva__c: p.Regime_Iva__c,
                Tempo_approvvigionamento__c: p.Tempo_approvvigionamento__c,
                mTaglieVarianti: p.mTaglieVarianti,
                possibilitaRicamo: prodConRicamo[nameV],
            }
            if(prodConRicamo[nameV]) prod['testoRicamo'] = ''
            // if(prod.taglie.length > 0) prod.ProductCode = `AB${i}`

            prods.push(prod);
        }

        // prods.sort((a, b) => b.LastModifiedDate - a.LastModifiedDate);
        context.commit('SET_PRODS', prods);
        context.commit('SET_SHOPPERS', shoppers);
        return prods;
      }
    },
    prezzoCategoria: function(context) {
      if (context.getters.diplomato)
        return 1
      if (context.getters.studente)
        return 2
      if (context.getters.dipendente)
        return 3
    },
    sortTaglie: function(context, array) {
      var w = {"10XS":1,"9XS":2,"8XS":3,"7XS":4,"6XS":5,"5XS":6,"4XS":7,"3XS":8,"2XS":9,"XS":10,"S":11,"M":12,"L":13,"XL":14,"2XL":15,"3XL":16,"4XL":17,"5XL":18,"6XL":19,"7XL":20,"8XL":21,"9XL":22,"10XL":23}

      return array.sort((a, b) => w[a] - w[b])
    },
    updateStep: function (context, payload) {
      context.commit('SET_STEP', { step: payload.step });
    },
    getLibri: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          // compilo payload con gli id delle opportunità
          console.log('getLibri')
          var data = {
            username: context.state.connection.username,
            callname: 'getLibri',
            crypt: context.state.connection.userdata
          }
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getLibri:', resp)
            context.commit('SET_LIBRI', resp)
            resolve(resp)
          })
        })
      })
    },
    saveApriPorta: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('saveApriPorta');
          var data = { callname: 'saveApriPorta', porta: payload.porta, crypt: context.state.connection.userdata };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('saveApriPorta:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    setTesi: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('setTesi');
          console.log('context.state.tesi', context.state.tesi)
          var data = { username: context.state.connection.username, callname: 'upsertTesi', crypt: context.state.connection.userdata, payload: context.state.tesi };
          apiclient.getcandidatura(context.state.connection, data)
            .then(function (resp) {
              console.log('setTesi:', resp)
              context.commit('SET_TESI', resp);
              resolve();
            });
        });
      })
    },
    getTesi: function( context, tipo ) {
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getTesi in index.js')
          // console.log('context',context)

          var idOpptyTesi = context.state.idOpptyTesi
          if(!context.state.idOpptyTesi) {
            context.commit('SET_TESI')
            idOpptyTesi = context.state.tesi.Opportunita__c
          }

          var data={ username: context.state.connection.username, callname:'getTesi', crypt: context.state.connection.userdata, idOppty: idOpptyTesi };
          
          apiclient.getcandidatura( context.state.connection, data)
          .then( function(resp) {
            console.log('resp',resp)
            console.log('tipo richiesto:',tipo)

            context.commit('SET_TESI', {} )

            if (resp.length>0) {
              for(var t of resp) {
                console.log('t.Tipo_Esame__c',t.Tipo_Esame__c)
                if(tipo == t.Tipo_Esame__c || (tipo == 'Esame Finale' && !t.Tipo_Esame__c)) {
                  context.commit('SET_TESI', { ... t });
                  break
                }
              }
            }

            resolve();
          });
        });
      })
    },
    getPiatto: function (context, idPiatto) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getPiatto');
          var data = { username: context.state.connection.username, callname: 'getPiatto', crypt: context.state.connection.userdata, idOppty: idPiatto };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getPiatto:');
            console.log(resp)
            context.commit('SET_PIATTO', resp);
            resolve(resp);
          });
        });
      })
    },
    setPiatto: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('setPiatto');
          var data = { username: context.state.connection.username, callname: 'upsertPiatto', crypt: context.state.connection.userdata, piatto: payload.piatto, ingredienti: payload.ingredienti };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('setPiatto:', resp)
            // context.commit('SET_PIATTO', resp );
            resolve(resp);
          });
        });
      })
    },
    getPrenotazione: function (context, payload) { //tipo
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getPrenotazione');
          var data = { username: context.state.connection.username, callname: 'getPrenotazione', crypt: context.state.connection.userdata, idAccount: payload.idAccount, tipo: payload.tipo }; // , tipo: tipo
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getPrenotazione:');
            console.log(resp)
            resolve(resp);
          });
        });
      })
    },
    createPrenotazione: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('createPrenotazione');
          var data = {
            username: context.state.connection.username, callname: 'createPrenotazione', crypt: context.state.connection.userdata,
            idEvento: payload.idEvento, idOppty: payload.idOppty, idCorso: payload.idCorso, isRipescato: payload.isRipescato
          };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('createPrenotazione:');
            console.log(resp)
            resolve(resp);
          });
        });
      })
    },
    annullaPrenotazione: function (context, idEvento) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('annullaPrenotazione');
          var data = { username: context.state.connection.username, callname: 'annullaPrenotazione', crypt: context.state.connection.userdata, idEvento: idEvento };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('annullaPrenotazione:');
            console.log(resp)
            resolve(resp);
          });
        });
      })
    },
    getEventi: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getEventi');
          var data = {
            username: context.state.connection.username,
            oppty: payload.oppty,
            callname: 'getEventi',
            crypt: context.state.connection.userdata,
            tipo: payload.tipo,
            data: payload.data,
            isRipescato: payload.isRipescato
          };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getEventi:', resp)
            resolve(resp);
          });
        });
      })
    },
    getCasi: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getCasi');
          var data = { username: context.state.connection.username, callname: 'getCasi', crypt: context.state.connection.userdata, idOppty: context.state.idHousing };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getCasi:');
            console.log(resp)
            context.commit('SET_CASI', resp);
            resolve(resp);
          });
        });
      })
    },
    getCategorieCasi: function (context) {
      return new Promise(resolve => {
        context.dispatch('loginbyid').then(() => {
          console.log('getCategorieCasi')

          var data = {
            username: context.state.connection.username,
            crypt: context.state.connection.userdata,
            callname: 'getCategorieCasi'
          }

          apiclient.getcandidatura(context.state.connection, data).then(resp => {
            console.log('getCategorieCasi response: ' + JSON.stringify(resp))
            context.commit('SET_CATEGORIE_CASI', resp)

            resolve(resp)
          })
        })
      })
    },
    getCondizioni: function(context, payload){
      return new Promise(function(resolve) {
        context.dispatch('loginbyid').then(function() {
          console.log('getCondizioniTesi');
          console.log('context',context)
          console.log('payload',payload)
          console.log(context.state.idOpptyTesi, !context.state.idOpptyTesi)
          let userHasTesi=context.getters.tesi;
          if (!userHasTesi) {
            console.log('getCondizioni senza tesi');
            apiclient.printonbackend( context.state.connection, { label: 'errore su getCondizioni', stato: context.state}).then( function() {
              alert("Errore non gestito, contattare l'assistenza");
              resolve({});
            });
          }
          var idOpptyTesi = context.state.idOpptyTesi
          if(!context.state.idOpptyTesi) {
            context.commit('SET_TESI')
            idOpptyTesi = context.state.tesi.Opportunita__c
          }
          console.log('idOpptyTesi',idOpptyTesi)
          var tipo = payload && payload.tipo ? payload.tipo : 'Esame Finale'
          console.log('tipo', tipo)
          var data={callname:'getCondizioniTesi', idOppty: idOpptyTesi, username: context.state.connection.username, crypt: context.state.connection.userdata, tipo: tipo};
          apiclient.getcandidatura( context.state.connection, data)
          .then( function(resp) {
            console.log('getCondizioniTesi:',resp);
            if(resp.Id !== undefined) context.commit('SET_CONDIZIONE', resp );
            resolve(resp);
          });
        });
      })
    },
    getCategoriaIngrAttr: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getCategoriaIngrAttr');
          var data = { callname: 'getCategoriaIngrAttr', tipo: payload.tipo, condizioneId: context.state.condizione.Id, username: context.state.connection.username, crypt: context.state.connection.userdata };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getCategoriaIngrAttr:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    getIngredientiAttrezzaturaTesi: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getIngredientiAttrezzaturaTesi');
          var data = { callname: 'getIngredientiAttrezzaturaTesi', tipo: payload.tipo, categoria: payload.categoria, condizioneId: context.state.condizione.Id, username: context.state.connection.username, crypt: context.state.connection.userdata };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getIngredientiAttrezzaturaTesi:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    getProdEcommerce: function (context, payload) {
      console.log(payload);
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getProdEcommerce');
          var data = { username: context.state.connection.username, callname: 'getProdEcommerce', crypt: context.state.connection.userdata, ricerca: payload.ricerca, filtro: payload.filtro, crit: payload.crit };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            resolve(resp);
          });
        });
      })
    },
    getOrdersEcommerce: function (context) {
      //console.log(payload)

      return new Promise(resolve => {
        context.dispatch('loginbyid').then(() => {
          console.log('getOrdersEcommerce')

          var data = {
            username: context.state.connection.username,
            crypt: context.state.connection.userdata,
            callname: 'getOrdersEcommerce'
          }

          apiclient.getcandidatura(context.state.connection, data).then(resp => resolve(resp))
        })
      })
    },
    recuperaCarrello: function (context) {
      console.log('recuperaCarrello')
      var dataCarrello = new Date(localStorage.getItem('dataCarrello'))
      // var dataCarrelloScaduto = dataCarrello.getTime() + 6.048e+8 //aggiungo una settimana
      var dataCarrelloScaduto = dataCarrello.getTime() + 8.64e+7 //aggiungo un giorno
      var adesso = Date.now()

      var localUserName = localStorage.getItem('userCarrello')
      var userName = context.state.userprofile.Username__pc

      if (userName == localUserName && adesso <= dataCarrelloScaduto) {
        context.commit('GET_CARRELLO')
      } else {
        console.log('è passata più di una settimana quindi svuoto il carrello oppure hai cambiato account')
        context.commit('RESET_CARRELLO')
      }
    },
    addProd: function (context, prod) {
      console.log('--> index.addProd')
      console.log(prod) //console.log(context.state.carrelloEcommerce)
      console.log('prod.img', prod.img)

      // identifica taglia o numero scarpe corretto
      if (prod.taglia || prod.numero) {
        prod.variants.forEach(v => {
          if (v.Sesso_Taglia__c.substring(1) === prod.taglia || v.Sesso_Taglia__c.substring(1) == prod.numero) {
            prod.Id = v.Id
            prod.Description = v.Description
            prod.ProductCode = v.ProductCode
          }
        })
      }

      if (context.state.carrelloEcommerce != 'null' && context.state.carrelloEcommerce.length > 0) {
        console.log('maggiore di 1')

        if ((context.state.carrelloEcommerce.map(a => a.Id)).includes(prod.Id)) {
          context.state.carrelloEcommerce.forEach(p => {
            if (p.Id === prod.Id) p.qty += prod.qty
          })
        } else context.state.carrelloEcommerce.push(JSON.parse(JSON.stringify(prod)))

        // console.log('context.state.carrelloEcommerce',context.state.carrelloEcommerce)
        // localStorage.setItem('carrello', JSON.stringify(context.state.carrelloEcommerce))
      } else {
        console.log('uguale a 0')

        context.state.carrelloEcommerce = []
        context.state.carrelloEcommerce.push(JSON.parse(JSON.stringify(prod)))

        // context.commit('SET_CARRELLO', context.state.carrelloEcommerce)
      }

      console.log('context.state.carrelloEcommerce', context.state.carrelloEcommerce)
      context.commit('SET_CARRELLO', context.state.carrelloEcommerce)

      localStorage.setItem('dataCarrello', new Date());
      localStorage.setItem('userCarrello', context.state.userprofile.Username__pc);

      var tot = 0
      if(context.state.carrelloEcommerce.length > 0) context.state.carrelloEcommerce.forEach( d => { tot+=parseInt(d.qty)* (d.Prezzo__c ? d.Prezzo__c : 0); })
      context.state.totaleEcommerce = tot.toFixed(2)
    },
    removeProd: function (context, index) {
      context.commit('REMOVE_PROD_CARRELLO', index)
      localStorage.setItem('dataCarrello', new Date());
    },
    createOpptyEcommerce: function (context, payload) {
      return new Promise(res => {
        context.dispatch('loginbyid').then(() => {
          console.log('createOpptyEcommerce')

          var data = {
            username: context.state.connection.username,
            crypt: context.state.connection.userdata,

            callname: 'createOpptyEcommerce',

            prods: payload.products,
            sessionId: payload.sessionId,
            paymentIntent: payload.paymentIntent,
            importo: payload.importo,
            isRicarica: payload.isRicarica,
            opptyCorso: payload.opptyCorso,
            clausolaReso: payload.clausolaReso
          }

          apiclient.getcandidatura(context.state.connection, data).then(resp => res(resp))
        })
      })
    },
    stripePayment: function (context, payload) {
      return new Promise(res => {
        context.dispatch('loginbyid').then(() => {
          console.log('stripePayment')

          var prods = []

          payload.prods
            ? prods = [{
              name: payload.prods[0].Name, descr: payload.prods[0].Description, img: '',
              qty: payload.prods[0].qty, price: payload.prods[0].Prezzo__c, size: ''
            }]
            : context.state.carrelloEcommerce.forEach(p =>
              prods.push({
                name: p.Name, descr: p.Description, img: '',
                qty: p.qty, price: p.Prezzo__c, size: ''
              })
            )

          var data = {
            username: context.state.connection.username,
            crypt: context.state.connection.userdata, // ?

            callname: 'stripePayment',

            tot: payload.tot,
            prods: prods,
            host: window.location.origin,
            lang: payload.locale
          }

          apiclient.getcandidatura(context.state.connection, data).then(resp => res(resp))
        })
      })
    },
    getCampi: function (context, oggetti) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getCampi');
          var data = { username: context.state.connection.username, callname: 'getCampi', crypt: context.state.connection.userdata, oggetti: oggetti };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            var objs = new Map();
            resp.forEach(function (c) {
              if (!objs.has(c.oggetto)) objs.set(c.oggetto, new Map());
              var o = objs.get(c.oggetto);
              o.set(c.name, c);
            })
            context.commit('SET_CAMPI', objs);
            resolve(resp);
          });
        });
      })
    },
    getIdComuneStatoRilascio: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getIdComuneStatoRilascio');
          var data = { username: context.state.connection.username, callname: 'getIdComuneStatoRilascio', crypt: context.state.connection.userdata, comuneName: payload.comuneName, provinciaName: payload.provinciaName, statoName: payload.statoName };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getIdComuneStatoRilascio:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    getProdotti: function (context, productcode) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getProdotti');
          var data = { username: context.state.connection.username, callname: 'getProduct', crypt: context.state.connection.userdata, idOppty: productcode };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getProdotti:');
            console.log(resp)
            context.commit('SET_PRODOTTI', resp);
            resolve(resp);
          });
        });
      })
    },
    getScuole: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getScuole');
          var data = { username: context.state.connection.username, callname: 'getScuole', crypt: context.state.connection.userdata };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getScuole:');
            console.log(resp);
            resolve(resp);
          });
        });
      })
    },
    getAccData: function (context, payload) {
      return new Promise(function (resolve) {
        console.log('getAccData');
        var data = { username: payload.username, crypt: payload.crypt, callname: 'getAccData', fields: payload.fields };
        apiclient.getAccData(context.state.connection, data).then(function (resp) {
          console.log('getAccData:');
          console.log(resp)
          resolve(resp);
        });
      })
    },
    setCandidatura: function (context, payload) {
      return new Promise(function (resolve) {
        console.log('setCandidatura');
        var data = { username: payload.account.Username__pc, callname: 'setCandidatura', account: payload.account, oppty: payload.oppty, corso: payload.corso, edizione: payload.edizione };
        apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
          console.log('setCandidatura:');
          console.log(resp)
          // context.commit('SET_PIATTO', resp );
          resolve(resp);
        });
      })
    },
    createAndLogin: function (context, payload) {
      return new Promise(function (resolve) {
        console.log('createAndLogin');
        var data = { username: payload.account.Username__pc, callname: 'createAndLogin', account: payload.account };
        apiclient.createAndLogin(context.state.connection, data).then(function (resp) {
          console.log('createAndLogin:');
          console.log(resp)
          if (resp.userdata == 'Bloccato') {
            console.log('bloccato');
            context.commit('SET_BLOCKED', true);
          } else {
            var connection = {
              commit: context.commit,
              username: data.username,
              token: resp.token,
              userdata: resp.userdata,
            };
            var user_data = { username: data.username, token: resp.userdata };
            context.commit('SET_BLOCKED', false);
            context.commit('SET_USER_DATA', user_data);
            context.commit('SET_CONNECTION', connection);
            context.commit('SET_USER_PROFILE', resp.account);
            var jwtpayload = jwt.sign(resp.account, apiclient.secret);
            localStorage.setItem('userprofile', jwtpayload);
            context.commit('GET_USER_DATA', resp.account);
            resolve();
          }
          resolve();
        });
      })
    },

    createOppty: function (context, payload) {
      return new Promise(function (resolve) {
        console.log('createOppty');
        var data = { username: payload.username, crypt: payload.crypt, callname: 'createOppty', oppty: payload.oppty, corso: payload.corso, edizione: payload.edizione };
        apiclient.createOppty(context.state.connection, data).then(function (resp) {
          console.log('createOppty:');
          console.log(resp)
          resolve(resp);
        });
      })
    },
    updateAccInfo: function (context, payload) {
      return new Promise(function (resolve) {
        console.log('updateAccInfo');
        var data = { username: payload.account.Username__pc, crypt: payload.crypt, callname: 'updateAccInfo', account: payload.account };
        apiclient.updateAccInfo(context.state.connection, data).then(function (resp) {
          console.log('updateAccInfo:');
          console.log(resp)
          resolve(resp);
        });
      })
    },
    uploadDoc: function (context, payload) {
      return new Promise(function (resolve) {
        console.log('uploadDoc');
        var data = { username: payload.username, crypt: payload.crypt, callname: 'uploadDoc', filename: payload.filename, payload: payload.payload, scadenza: payload.scadenza, tipo: payload.tipo, name: payload.name };
        apiclient.uploadDoc(context.state.connection, data).then(function (resp) {
          console.log("uploadDoc")
          console.log(resp)
          
          if(resp.error) {
            console.log('ERRORE: '+resp.error[0].errorCode+': '+resp.error[0].message)
            context.dispatch('showError', { error: resp.error })
            return false
          }

          resolve(resp);
        })
      })
    },
    loginUser: function (context, data) {
      return new Promise(function (resolve) {
        apiclient.loginUser(context.state.connection, data).then(function (resp) {
          console.log(resp)
          var connection = {
            commit: context.commit,
            username: data.username,
            token: resp.token,
            userdata: resp.userdata,
          };
          console.log(connection);

          var user_data = { username: data.username, token: resp.userdata };
          console.log(user_data);
          context.commit('SET_BLOCKED', false);
          context.commit('SET_USER_DATA', user_data);
          context.commit('SET_CONNECTION', connection);
          resolve();
        });
      });
    },
    setCodiceVoucher: function (context, payload) {
      context.state.vouchercode = payload
    },
    setCandidatoVoucher: function (context, payload) {
      return new Promise(function (resolve) {
        console.log('setCandidatoVoucher');
        var data = { username: payload.account.Username__pc, callname: 'setCandidatoVoucher', vouchercode: context.state.vouchercode, account: payload.account };
        apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
          console.log('setCandidatoVoucher:');
          console.log(resp)
          // context.commit('SET_PIATTO', resp );
          resolve(resp);
        });
      })
    },
    spostaOppty: function (context, vouchercode) {
      return new Promise(function (resolve) {
        console.log('spostaOppty');
        var data = { username: context.state.connection.username, callname: 'spostaOppty', crypt: context.state.connection.userdata, vouchercode: vouchercode };
        apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
          console.log('spostaOppty:');
          console.log(resp)
          // context.commit('SET_PIATTO', resp );
          resolve(resp);
        });
      })
    },
    pagaOra: function (context, tipo) {
      return new Promise(function (resolve) {
        console.log('creaOppty');
        var lrigheOrd = [];
        context.state.carrelloEcommerce.forEach(function (d, i) {
          var r = {
            Quantita__c: d.qty, Prezzo__c: d.Prezzo__c, Descrizione__c: d.Description,
            Tipo__c: d.Family == 'Libri' ? 'L' : 'D', Ordinatore__c: i + 1
          };
          if (d.taglia != undefined && d.taglia != '') r.Taglia__c = d.taglia;
          if (d.numero != undefined && d.numero != '') r.Taglia__c = d.numero;
          lrigheOrd.push(r);
        })
        var data = {
          username: context.state.connection.username,
          callname: 'creaOpptyEcommerce',
          crypt: context.state.connection.userdata,
          prods: lrigheOrd,
          tipo: tipo,
        };
        apiclient.getcandidatura(context.state.connection, data)
          .then(function (resp) {
            console.log('creaOpptyEcommerce:', resp)
            // context.commit('SET_PIATTO', resp );
            resolve(resp);
          });
      })
    },
    doRecover: function (context) {
      return new Promise(function (resolve) {
        console.log('doRecover');
        // var data={ email: context.state.emailRecovery, callname:'dorecover'};
        // apiclient.getcandidatura( context.state.connection, data)
        // .then( function(resp) {
        //   console.log('doRecover resp:', resp)
        //   resolve(resp);
        // });
        context.dispatch('loginbyid').then(function () {
          console.log('doRecover');
          var data = { email: context.state.emailRecovery, callname: 'dorecover' };
          apiclient.getcandidatura(context.state.connection, data)
            .then(function (resp) {
              console.log('lastRecordedCheckIn resp', resp);
              resolve(resp);
            });
        });
      })
    },
    setNewPwd: function (context) {
      return new Promise(function (resolve) {
        console.log('setNewPwd');
        var data = { tkn: context.state.tkn, newpwd: context.state.newpwd, newpwdconfirm: context.state.newpwdconf, callname: 'setnewpwd' };
        console.log(data);
        apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
          console.log('setnewpwd resp:')
          console.log(resp)
          resolve(resp);
        });
      })
    },
    updateProfilo: function (context) {
      return new Promise(function (resolve) {
        console.log('updateProfilo');
        var data = { username: context.state.connection.username, callname: 'updateProfilo', account: context.state.userprofile };
        apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
          console.log('updateProfilo:');
          console.log(resp)
          context.commit('SET_USERPROFILE_DURTY', false);
          resolve(resp);
        });
      })
    },
    sendtoHB: function (context) {
      return new Promise(function (resolve) {
        console.log('sendtoHB');
        var data = { username: context.state.connection.username, callname: 'sendtoHB', account: context.state.userprofile, edizioneCorso: context.state.productcode };
        apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
          console.log('sendtoHB:');
          console.log(resp);
          context.commit('SET_PURCHASED', true);
          resolve(resp);
        });
      })
    },
    getRegioniProvincie: function (context, tipo) {
      return new Promise(function (resolve) {
        console.log('getRegioniProvincie');
        var data = { callname: 'getRegioniProvincie', tipo: tipo };
        console.log('context.state.connection', context.state.connection)
        console.log('data', data)
        apiclient.getcandidatura(context.state.connection, data)
          .then(function (resp) {
            console.log('getRegioniProvincie:', resp);
            resolve(resp);
          });
      })
    },
    setCaso: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('setCaso');
          var data = { username: context.state.connection.username, callname: 'upsertCaso', crypt: context.state.connection.userdata, caso: payload };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('setCaso:');
            console.log(resp)
            // context.commit('SET_PIATTO', resp );
            resolve(resp);
          });
        });
      })
    },
    getCorsiAttivi: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getCorsiAttivi');
          var data = { username: context.state.connection.username, callname: 'getCorsiAttivi', crypt: context.state.connection.userdata };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getCorsiAttivi:');
            console.log(resp)
            context.commit('SET_CORSI', resp);
            resolve();
          });
        });
      })
    },
    getMateriali: function (context, locale) {
      return new Promise(function (resolve) {
        console.log('getMateriali');
        var language = 'it_IT';
        if (locale == 'en') language = 'en_EN';
        var idCorsi = [];
        var idOpp = [];
        context.state.corsi.forEach(function (c) {
          idOpp.push(c.Id);
          idCorsi.push(c.Edizione_Corso__r.Corso__c);
        })
        console.log('idCorsi');
        console.log(idCorsi);
        console.log(language);
        var data = { username: context.state.connection.username, callname: 'getMateriali', crypt: context.state.connection.userdata, language: language, idCorsi: idCorsi, idOpp: idOpp };
        apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
          console.log('getMateriali:');
          console.log(resp)
          context.commit('SET_MATERIALI', resp);
          resolve();
        });
      });
    },
    getCandidatura: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getCandidatura');
          var data = { username: context.state.connection.username, callname: 'getCandidatura', crypt: context.state.connection.userdata };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getCandidatura:');
            console.log(resp)
            context.commit('SET_CANDIDATURA', resp);
            resolve();
          });
        });
      })
    },
    subscribePush: function (context, subscription) {
      return new Promise(function (resolve) {
        apiclient.subscribePush(subscription).then(function (resp) {
          console.log('resp subscribePush', resp)

          if (resp.data.success) {
            console.log('invio iscrizione notifiche');
            var data = { callname: 'subscribePush', subscription: JSON.stringify(subscription), crypt: context.state.connection.userdata };
            apiclient.getcandidatura(context.state.connection, data);
            alert('Notifiche attivate con successo');
          } else {
            console.log('il dispositivo è già iscritto alle notifiche');
            alert('Il dispositivo è già iscritto alle notifiche');
          }

          return resolve(resp);
        })
      })
    },
    getVisto: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getVisto');
          var data = { callname: 'getVisto', username: context.state.connection.username, crypt: context.state.connection.userdata };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getVisto:', resp)
            context.commit('SET_VISTO', resp[0]);
            resolve();
          });
        });
      })
    },
    updateVisto: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('updateVisto');
          var data = { callname: 'updateVisto', visto: payload.visto };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('updateVisto:', resp)
            context.commit('SET_VISTO', resp);
            resolve();
          });
        });
      })
    },
    getHousing: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getHousing');
          var data = { username: context.state.connection.username, callname: 'getHousing', crypt: context.state.connection.userdata };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getHousing:');
            console.log(resp)
            context.commit('SET_HOUSING', resp);
            resolve();
          });
        });
      })
    },
    getDocumenti: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getDocumentiCandidatura');
          if (context.state.candidatura[0] > 0) console.log('oppty x doc: ', context.state.candidatura[0].Id)
          var data = { username: context.state.connection.username, callname: 'getDocumentiCandidatura', crypt: context.state.connection.userdata, idOppty: context.state.candidatura[0].Id };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getDocumentiCandidatura:', resp)
            context.commit('SET_DOCUMENTI', resp);
            resolve();
          });
        });
      })
    },
    getPagamenti: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getPagamenti');
          var data = { username: context.state.connection.username, callname: 'getPagamenti', crypt: context.state.connection.userdata };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getPagamenti:');
            console.log(resp)
            context.commit('SET_PAGAMENTI', resp);
            resolve();
          });
        });
      })
    },

    deregister: function ({ commit }) {
      commit('RESET_USER_DATA');
    },
    register: function (context, data) {
      apiclient.register(context.state.connection, data).then(function (resp) {
        console.log(resp)
        var connection = {
          commit: context.commit,
          username: data.username,
          token: resp.token,
          userdata: resp.userdata,
        };
        context.commit('SET_CONNECTION', connection);
        var user_data = { username: data.username, token: resp.userdata };
        context.commit('SET_USER_DATA', user_data);
      });
    },
    checkUser: function (context, data) {
      return apiclient.query(context.state.connection, data).then(function (resp) {
        console.log(resp);
        if (resp && resp[0].Id != null) context.commit('SET_REG_DENIED', true);
      });
    },
    searchVoucher: function (context, data) {
      return new Promise(function (resolve) {
        apiclient.query(context.state.connection, data).then(function (resp) {
          console.log(resp);
          resolve(resp);
        });
      });
    },
    getAbbigliamento: function (context, data) {
      return apiclient.query(context.state.connection, data).then(function (resp) {
        console.log(resp);
        context.commit('SET_ABBIGLIAMENTO_CANDIDATURA', resp);
      });
    },
    getCategoria: function (context, data) {
      context.dispatch('loginbyid').then(function () {
        return apiclient.query(context.state.connection, data).then(function (resp) {
          console.log('getcategoria');
          console.log(resp);
          var lcat = [] // = ['Tutte le categorie'];
          for (var d of resp) {
            // resp.forEach(function(d){
            if (d.Family == 'Shopper') continue
            lcat.push(d.Family);
            // });
          }
          context.commit('SET_CATEGORIA_ECOMMERCE', lcat);
        });
      })
    },
    /*
    getImages: function (context, ids) {
      return new Promise(resolve => {
        context.dispatch('loginbyid').then(() => {
          console.log('getProductImages')
          var data = { callname: 'getProductImages', ids: ids }
          apiclient.getcandidatura(context.state.connection, data).then(resp => {
            // console.log('resp:', resp)
            resolve(resp)
          })
        })
      })
    }, */
    getImage: function (context, payload) {
      if (this.state.imgEcommerce[payload.id]) return this.state.imgEcommerce[payload.id]
      else return new Promise(resolve => {
        context.dispatch('loginbyid').then(() => {
          console.log('getProductImage')
          var data = { callname: 'getProductImage', id: payload.id, limit: payload.limit }
          apiclient.getcandidatura(context.state.connection, data).then(resp => {
            this.state.imgEcommerce[payload.id] = resp
            resolve(resp)
          })
        })
      })
    },
    getAttachment: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getAttachment');
          var data = { callname: 'getAttachment', idFile: payload.idFile, parentId: payload.parentId };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getAttachment:', resp);
            resolve(resp);
          });
        });
      })
    },
    getContentDocument: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('getContentDocument');
          var data = { callname: 'getContentDocument', idFile: payload.idFile };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('getContentDocument:', resp);
            resolve(resp);
          });
        });
      })
    },
    updateCandidatura: function (context, payload) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('updateCandidatura');
          var data = { callname: 'updateOpportunity', oppty: payload };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('updateOpportunity:');
            console.log(resp);
            if (resp) {
              context.state.candidatura[0].Fatturante_compilato__c = true;
            }
            //context.commit('GET_USER_DATA');
            resolve(resp);
          });
        });
      })
    },
    getProfile: function (context) {
      return new Promise(function (resolve) {
        console.log('getProfile');
        console.log(context.state.connection.username, context.state.connection.userdata)
        var data = { username: context.state.connection.username, crypt: context.state.connection.userdata };
        apiclient.getprofile(context.state.connection, data).then(function (resp) {
          console.log('getprofile resp')
          console.log(resp);
          context.commit('SET_USER_PROFILE', resp);

          var jwtpayload = jwt.sign(resp, apiclient.secret);
          localStorage.setItem('userprofile', jwtpayload);

          context.commit('GET_USER_DATA', resp);

          resolve();
        });
      })
    },
    getOrdiniEcommercePronti: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid')
          .then(function () {
            console.log('getOrdiniEcommercePronti');
            var data = { callname: 'getOrdiniEcommercePronti', username: context.state.connection.username, crypt: context.state.connection.userdata };
            apiclient.getcandidatura(context.state.connection, data)
              .then(function (resp) {
                console.log('getOrdiniEcommercePronti:', resp)
                context.commit('SET_ORDINI_ECOMMERCE', resp);
                resolve();
              });
          })
      })
    },
    lastRecordedCheckin: function (context) {
      return new Promise(function (resolve) {
        context.dispatch('loginbyid').then(function () {
          console.log('lastRecordedCheckIn');
          var data = { callname: 'lastRecordedCheckin', username: context.state.connection.username, crypt: context.state.connection.userdata };
          apiclient.getcandidatura(context.state.connection, data).then(function (resp) {
            console.log('lastRecordedCheckIn resp', resp);
            //context.commit('SET_USER_PROFILE', resp );

            //var jwtpayload = jwt.sign( resp, apiclient.secret );
            //localStorage.setItem('userprofile', jwtpayload);

            //context.commit('GET_USER_DATA', resp );

            resolve(resp);
          });
        });
      })
    },
    signInWithProvider: function (context, payload) {
      authClientLib.signIn(payload)
    },
    login: function (context, data) {
      return new Promise(function (resolve) {
        apiclient.login(context.state.connection, data).then(function (resp) {
          console.log(resp)
          if (resp.userdata == 'Bloccato') {
            console.log('bloccato');
            context.commit('SET_BLOCKED', true);
          } else {
            var connection = {
              commit: context.commit,
              username: data.username,
              token: resp.token,
              userdata: resp.userdata,
            };
            var user_data = { username: data.username, token: resp.userdata };
            context.commit('SET_BLOCKED', false);
            context.commit('SET_USER_DATA', user_data);
            context.commit('SET_CONNECTION', connection);
          }
          resolve();
        });
      });
    },
    setConnectionWithcontextCommit: function (context, data) {
      return new Promise((resolve, reject) => {
        try {
          // Construct the connection object
          const connection = {
            commit: context.commit,
            username: data.username,
            token: data.token,
            userdata: data.userdata,
          };

          // Construct the user_data object
          const user_data = {
            username: data.username,
            token: data.userdata,
          };

          // Commit the necessary mutations
          context.commit('SET_BLOCKED', false);
          context.commit('SET_USER_DATA', user_data);
          context.commit('SET_CONNECTION', connection);
          console.log("setConnectionWithcontextCommit");

          console.log('Connection successfully set:', connection);
          resolve(); // Resolve the promise
        } catch (error) {
          console.error('Error during setConnectionWithcontextCommit:', error);
          reject(error); // Reject the promise in case of an error
        }
      });
    },
    loginbyid: function (context) {
      return new Promise(function (resolve) {
        if (context.state.connection.token) {
          resolve();
          return;
        }
        console.log('loginbyid', context.state.connection.username, context.state.connection.userdata)
        var data = { username: context.state.connection.username, crypt: context.state.connection.userdata };
        apiclient.loginbyid(context.state.connection, data).then(function (resp) {
          console.log(resp)
          var connection = {
            commit: context.commit,
            username: data.username,
            token: resp.token,
            userdata: resp.userdata,
          };
          var user_data = { username: data.username, token: resp.userdata };
          context.commit('SET_USER_DATA', user_data);
          context.commit('SET_CONNECTION', connection);
          resolve();
        });
      });
    },
    getPrefissi: function( ) {
      return apiclient.getprefissi().then( function(resp) {
        console.log('getprefissi');
        let prefissi = [];
        for (let nazione of resp) {
          prefissi.push({nome:nazione.Name, prefisso:nazione.Prefisso_internazionale__c});
        }
        return prefissi;
      });
    },
    search_records: async function(context, payload) {
      console.log('search_records payload', payload);
      
      try {
        let resp=await apiclient.search( context.state.connection, payload.object, payload.properties );
        console.log('search_records resp',resp);

        if(resp.error) {
          console.log('ERRORE: '+resp.error[0].errorCode+': '+resp.error[0].message)
          return false
        }

        return resp;

      } catch(err) {
        console.log('search_records failed', payload);
        console.log(err);
        return false;
      }
      
    },
    showError: function(context, payload) {
      console.log('error message shown');
      let error = payload.error
      let errList = []
      error.forEach( e => {
        errList.push(e.errorCode+': '+e.message)
      })
      alert(errList)
    },
  },
  modules: {
  }
})

<template>
  <div class="row" style="text-align: center; margin: 5px">
    <div class="col-sm-12 col-md-12">
      <mark>{{ label}}</mark>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'myMark',
  props: {
    label: String,
  },
  data: function () {
    return {};
  },
  mounted: function () { },
  methods: {}
}
</script>

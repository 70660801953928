<template>
  <!-- Nav menu -->
  <nav class="navbar sticky-top navbar-expand-lg blu-alma navbar-dark">
    <!-- Brand -->
    <a v-if="$i18n.locale == 'it'" class="navbar-brand" href="#"><img src="img/logoitbianco.png" class="logo" alt="Alma Logo" data-retina="true"></a>
    <a v-else class="navbar-brand" href="#"><img src="img/logoenbianco.png" class="logo" alt="Alma Logo" data-retina="true"></a>

    <!-- Toggler/collapse Button -->
    <div class="d-lg-none d-xl-none d-xxl-none"> 
      <a href="/#/utility">
        <button v-if="$store.getters.logged && $store.state.opptyCorso.IsActiveAperturaPorte__c" type="button" class="btn text-light align-top">
          <i class="bi bi-door-open-fill"></i>
        </button>
      </a>

      <button v-if="$store.getters.logged && notifiche > 0" @click="setShow" type="button" class="btn text-light align-top">
        <i class="bi bi-bell-fill"></i>
        <span class="badge badge-pill badge-warning font-size-4rem align-top">{{ notifiche }}</span>
      </button>
        
      <button @click="show = !show" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <!-- Navbar links -->
    <div :class="show ? 'collapse.show':'collapse'" class="navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">

        <li class="nav-item" v-for="item in menulist" :key="item.title">
          <a class="nav-link" :href="'/#/'+item.path" @click="show = false">{{ $t(item.title) }}</a>
        </li>

        <li class="nav-item" v-if="$store.getters.logged">
          <a class="nav-link" @click.prevent="goto({ path: 'Interventi', params: { from: from } })">{{ $t('ASSISTENZA') }}</a>
        </li>

        <li class="nav-item" v-if="$store.getters.isEcommerceVisible">
          <a class="nav-link" href="/#/ecommerce" @click="show = false">{{ $t('SHOP') }}</a>
        </li>

        <li class="nav-item" v-if="$store.getters.logged">
          <a class="nav-link" href="" @click.prevent="gotoProfilo()">
            <i class="bi bi-person-circle"></i>
            <span class="pl-1">
              {{ ($store.state.userprofile.FirstName ? $store.state.userprofile.FirstName : '') + ' ' + ($store.state.userprofile.LastName ? $store.state.userprofile.LastName : '') }}
            </span>
          </a>
        </li>

        <li class="nav-item" v-if="$store.getters.logged && $store.state.opptyCorso.IsActivePresenze__c">
          <a class="nav-link" href="/#/presenze" @click="show = false">{{ $t('PRESENZA') }}</a>
        </li>

        <li class="nav-item" v-if="$store.getters.logged && ($store.state.opptyCorso.IsActive__c || $store.state.opptyCorso.IsActiveAperturaPorte__c)">
          <a class="nav-link" href="/#/utility" @click="show = false">{{ $t('UTILITY')}}</a>
        </li>

        <li class="nav-item" v-if="!$store.getters.logged">
          <a class="nav-link" @click.prevent="gotoProfilo()">{{ $t('registrati') }}</a>
        </li>

        <li class="align-content-around ml-3">
          <!-- <a class="nav-link" @click.prevent="cambia">{{ $t('cambialingua') }}</a> -->
          <a v-for="(l, i) of iconeLingue" :key="l" :class="{'opacity-50 pe-none' : disableIcone }" @click.prevent="cambia(i)"><span :class="'m-1 fi fi-'+l"></span></a>
        </li>
      </ul>
       
    </div>

    <!-- Toggler/collapse Button -->
    <div class="d-none d-lg-block"> 
      <a href="/#/utility">
        <button v-if="$store.getters.logged && $store.state.opptyCorso.IsActiveAperturaPorte__c" type="button" class="btn text-light align-top">
          <i class="bi bi-door-open-fill"></i>
        </button>
      </a>

      <button v-if="$store.getters.logged && notifiche > 0" @click="setShow" type="button" class="btn text-light align-top">
        <i class="bi bi-bell-fill"></i>
        <span class="badge badge-pill badge-warning font-size-4rem align-top">{{ notifiche }}</span>
      </button>
    </div>

    <modalnotify :show="showmodal" :notifichetxt="notstxt" @cancel="setShow"></modalnotify>
  </nav>
</template>

<script>
  import modalnotify from './modalnotify.vue'
  import "/node_modules/flag-icons/css/flag-icons.min.css"
  
  export default {
    name: 'navbar',
    components: {
      modalnotify
    },
    props: {
      menulist: Array,
      from:     String
    },
    data: function() {
      return {
        show:      false,
        lingua:    'it', // fallback
        showmodal: false,
        notifiche: 0,
        notstxt:   [],
      }
    },
    watch: {},
    computed: {
      step(){
        return this.$store.getters.getstep
      },
      lingue() {
        var langs = ['it','en']
        if(this.$route.path.includes('candidatura')) langs.push('zh')
        return langs
      },
      iconeLingue() {
        var icons = ['it','gb']
        if(this.$route.path.includes('candidatura')) icons.push('cn')
        return icons
      },
      disableIcone() {
        if(this.$route.path.includes('candidatura') && this.step>1) return true
        return false
      }
    },
    mounted: function() {
      console.log("mounted navbar");
      
      this.getNotifiche()

      if (this.from) {
        this.menulist.forEach(item => {
          if (item.path == 'Interventi')
            item.params = { from: this.from }
        })
      }
    },
    methods: {
      setShow: function() {
        this.showmodal = !this.showmodal
      },
      cambia: function(pos) {
        console.log('cambia lingua: ' + this.$i18n.locale)
        console.log(this.$i18n)

        this.show = false
        
        this.$i18n.locale = this.lingue[pos]
        localStorage.setItem('lingua', this.lingue[pos]);
        console.log('this.$i18n.locale',this.$i18n.locale)
        return

        // VERSIONE CON DUE LINGUE
        /*
        if (!this.$i18n.locale) {
          if (this.lingua == 'en') {
            this.$i18n.locale = 'it'
            this.setMenu('it')
          } else {
            this.$i18n.locale = 'en'
            this.setMenu('en')
          }
          return
        }

        console.log('cambia lingua standard')

        if (this.$i18n.locale == 'en') {
          this.$i18n.locale = 'it'
          this.setMenu('it')
          return
        }

        if (this.$i18n.locale == 'it') {
          this.$i18n.locale = 'en'
          this.setMenu('en')
          return
        }
        */
      },
      setMenu: function(lang) {
        this.lingua = lang
        this.$store.commit('SET_LINGUA', this.lingua)
        console.log('LINGUA',this.$store.state.lingua)

        var mainmenu = []
        
        if (lang == 'it') {
          mainmenu = [
            { title: 'HOME',             path: ''       },
            { title: 'INFO GENERALI',    path: 'Info'       },
            { title: 'AREA CANDIDATURA', path: 'Candidati'  },
            { title: 'MY ALMA LIFE',     path: 'MyAlmaLife' },
            //{ title: 'ASSISTENZA',       path: 'Interventi', params: { from: this.from }} // path: 'Assistenza'
          ]

          this.$store.commit('SET_MAINMENU', mainmenu)
          console.log('$store.state.mainmenu',this.$store.state.mainmenu)
          return
        }

        if (lang == 'en') {
          mainmenu = [
            { title: 'HOME',           path: ''       },
            { title: 'GENERAL INFO',   path: 'Info'       },
            { title: 'CANDIDACY AREA', path: 'Candidati'  },
            { title: 'MY ALMA LIFE',   path: 'MyAlmaLife' },
            //{ title: 'ASSISTANCE',     path: 'Interventi', params: { from: this.from }} // path: 'Assistenza'
          ]

          this.$store.commit('SET_MAINMENU', mainmenu)
          console.log('$store.state.mainmenu',this.$store.state.mainmenu)
          return
        }
      },
      gotoProfilo: function() {
        this.show = false
        this.$router.push({ name: 'Profilo' })
      },
      goto: function(item) {
        if (this.$store.state.routerlock) {
          if (!confirm('Si desidera uscire?')) {
            this.show = false
            return
          }

          this.$store.commit('SET_ROUTERLOCK', false)
        }

        // console.log('goto:')
        // console.log(item)

        this.$emit('goto')

        this.show = false
        this.$router.push({ name: item.path, params: item.params })
      },
      getNotifiche: function() {
        if (this.$store.state.userprofile.Opportunit_con_richiesta_dati__c > 0 && this.$store.state.opptyCorso.Fatturante_compilato__c === false) {
          this.notifiche += 1
          this.notstxt.push({ txt: 'Compilare o Confermare dati fatturante', istruzioni:'Fatturazione > Fatturazione Corsi', link:'Profilo' })
        }

        if (this.$store.state.opptyCorso.Visibilit_abbigliamento__c && this.$store.state.opptyCorso.Abbigliamento_Compilato__c === false) {
          this.notifiche += 1
          this.notstxt.push({ txt: 'Compilare o Confermare dati abbigliamento', istruzioni:'> Abbigliamento', link:'Candidato' })
        }

        if (this.$store.state.opptyHousingFatt.Housing_compilazione_dati__c && this.$store.state.opptyHousingFatt.Fatturante_Housing_compilato__c === false) {
          this.notifiche += 1
          this.notstxt.push({ txt: 'Compilare o Confermare dati fatturante housing', istruzioni:'Fatturazione > Fatturazione Housing', link:'Profilo' })
        }

        if (this.$store.state.opptyCorso.Documenti_richiesti__c === true) {
          this.notifiche += 1
          this.notstxt.push({ txt: 'Carica Documenti', istruzioni:'> Documenti', link:'Candidato' })
        }

        if(this.$store.state.righeOrdineEcommerce.length > 0) {
          for(var ro of this.$store.state.righeOrdineEcommerce) {
            console.log('ro',ro)
            this.notifiche += 1
            this.notstxt.push({ 
              txt: 'Ordine N.'+ ro.Name +' pronto al ritiro', 
              istruzioni:'Il tuo '+ro.Descrizione__c+' è pronto', 
              link:'EcommerceStorico' 
            })
          }
        }
      }
    }
  }
</script>

<style scoped>
  /* Alerts button */
  /* Mobile */
  .font-size-4rem {
    font-size: .4rem !important;
  }
  .opacity-50 {
    opacity: 0.5;
  }
</style>
<template>
  <div class="form-group">
    <label>{{ placeholder }}*</label>
    <input type="file" id="file" ref="file" accept=".pdf,.docx,.doc" v-on:change="fileUpload" name="curriculum"
      :class="required ? (isValid ? 'is-valid' : 'is-invalid') : ''" style="padding: 3px" class="form-control" />
    <!-- v-bind:class="!fileData || erroredimensioni ? 'is-invalid' : 'is-valid'"  -->
    <br />
    <label for="curriculum">{{ $t('FilecaricareLabel') }}</label>
    <div v-if="erroredimensioni">
      <label>{{ $t('Dimensioni') }}</label> {{ fileMB }} MB<br />
      <div class="alert alert-danger" role="alert">{{ $t('DimensioniEccessive')}}</div><br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'fieldFile',
  props: {
    label: String,
    placeholder: String,
    required: Boolean,
    isValid: Boolean,
    settings: Object
  },
  data(){
    return{
      erroredimensioni:false,
      myfile: {},
      fileMB:0,
      fileData:''
    }
  },
  methods: {
    fileUpload: function () {
      if (this.$refs.file.files.length > 0) {
        this.myfile = this.$refs.file.files[0];
        const reader = new FileReader();
        reader.onload = this.doReadFile
        reader.readAsDataURL(this.myfile);
      }
    },
    doReadFile(e) {
      
      this.fileMB = this.myfile.size / 1000000
      console.log('OUTPUT :  this.fileMB',  this.fileMB);
      this.fileData = e.target.result.split(',')[1];
      this.notifyParent()
    },
    notifyParent() {
      const fileInfo = {
        name: this.myfile.name,
        fileData: this.fileData,
        fileMB: this.fileMB
      };

      this.erroredimensioni = this.fileMB>this.settings.maxMB
      this.$emit('changed', JSON.stringify(fileInfo));
    }
  },

}
</script>

<template>
  <div class="progress col-sm-12 col-md-12 ">
    <div class="progress-bar" role="progressbar" v-bind:style="'width: ' + currentStep / maxstep * 100 + '%'"
      v-bind:aria-valuenow="currentStep / maxstep" aria-valuemin="0" v-bind:aria-valuemax="maxstep">{{
        currentStep }}</div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'myMark',
  props: {
    currentStep:Number,
    maxstep:Number,
  },
  data: function () {
    return {};
  },
  mounted: function () { },
  methods: {}
}
</script>

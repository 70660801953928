<template>
  <div class="panel panel-primary">
    <!-- title, every step has one  -->
    <myTitle type="title" :translatedLabel="$t(stepProgram.panel_title)"></myTitle>

    <div class="panel-body">
      <div v-for="(fieldPair, index) in pairedFields" :key="step + '-' + index +'-' + 0">
        <!-- this part will be in the body but as single element -->
        <myMark v-if="fieldPair[0].type == 'mark' && fieldPair[0].on" :key="step + '-' + index +'-' + 1" :fieldKey="step + '-' + index +'-' + 1" :label="$t(fieldPair[0].label)"></myMark>
        <field
          v-else-if="fieldPair[0].type == 'fieldRadioButtons' && fieldPair[0].settings.inSeparatedRow && fieldPair[0].on"
          :key="step + '-' + index +'-' + 2" :fieldKey="step + '-' + index +'-' + 2" :type="fieldPair[0].type" :label="fieldPair[0].label" :placeholder="$t(fieldPair[0].label)"
          :settings="fieldPair[0].settings" :required="fieldPair[0].required" :is-valid="fieldPair[0].isValid" :fieldValue="fieldPair[0].value"
          @changed="handleChange($event, fieldPair[0].originalIndex)" class="row" />
        <field
          v-else-if="fieldPair[0].type == 'fieldFile' && fieldPair[0].settings.inSeparatedRow && fieldPair[0].on"
          :key="step + '-' + index +'-' + 5" :fieldKey="step + '-' + index +'-' + 5" :type="fieldPair[0].type" :label="fieldPair[0].label" :placeholder="$t(fieldPair[0].label)"
          :settings="fieldPair[0].settings" :required="fieldPair[0].required" :is-valid="fieldPair[0].isValid"
          @changed="handleChange($event, fieldPair[0].originalIndex)" class="row" />

        <div v-else class="row">
          <!-- First field in the pair -->
          <field v-if="fieldPair[0].on" :key="step + '-' + index +'-' + 3" :fieldKey="step + '-' + index +'-' + 3" :type="fieldPair[0].type" :label="fieldPair[0].label"
            :placeholder="$t(fieldPair[0].label)" :settings="fieldPair[0].settings" :translatedLabel2="label2" :required="fieldPair[0].required"
            :isValid="fieldPair[0].isValid" :options="fieldPair[0].options" :fieldValue="fieldPair[0].value"
            class="col-sm-12 col-md-6" @changed="handleChange($event, fieldPair[0].originalIndex)" />
          <!-- Second field in the pair, if it exists -->
          <field v-if="fieldPair[1] && fieldPair[1].on" :key="step + '-' + index +'-' + 4" :fieldKey="step + '-' + index +'-' + 4" :type="fieldPair[1].type" :label="fieldPair[1].label"
            :placeholder="$t(fieldPair[1].label)" :settings="fieldPair[1].settings" :translatedLabel2="label2" :required="fieldPair[1].required"
            :isValid="fieldPair[1].isValid" :options="fieldPair[1].options" :fieldValue="fieldPair[1].value"
            class="col-sm-12 col-md-6" @changed="handleChange($event, fieldPair[1].originalIndex)" />
        </div>
      </div>
    </div>


  </div>
</template>


<script>
import field from '@/components/field.vue'
import myTitle from '@/components/myTitle.vue'
import myMark from '@/components/myMark.vue'

export default {
  name: 'fieldset2',
  components: {
    field,
    myTitle,
    myMark,
  },
  props: {
    stepProgram: Object,
    step: Number,

  },
  data() {
    return {
      label2:""
    }
  },
  created() {
    this.label2 = this.$t("Scegliunaopzione");
  },
  computed: {
    pairedFields() {
      const pairs = [];
      const fields = this.stepProgram.fields;
      let i = 0;

      while (i < fields.length) {
        // Skip the field if `on` is false
        if (!fields[i].on) {
          i++; // Move to the next field
          continue;
        }

        // If `on` is true, define the current field
        const currentField = { ...fields[i], originalIndex: i };

        // Case when the field is a 'mark' or 'fieldRadioButtons' with inSeparatedRow = true
        if (currentField.type === 'mark' || (currentField.type === 'fieldRadioButtons' && currentField.settings.inSeparatedRow) || (currentField.type === 'fieldFile' && currentField.settings.inSeparatedRow)) {
          pairs.push([currentField]);
          i++; // Move to the next field
          continue;
        }

        // Look for the next valid field to pair with
        let nextField = null;
        let j = i + 1;

        // Search for the next field with `on: true`
        while (j < fields.length && (!fields[j].on || (fields[j].type === 'fieldRadioButtons' && fields[j].settings.inSeparatedRow)) || j < fields.length && (!fields[j].on || (fields[j].type === 'fieldFile' && fields[j].settings.inSeparatedRow))) {
    j++; // Skip invalid fields and fieldRadioButtons with inSeparatedRow
  }

        // If a valid next field is found, pair it with the current field
        if (j < fields.length && fields[j].on) {
          nextField = { ...fields[j], originalIndex: j };
        }

        // If a valid next field was found, pair them, otherwise just push the current field
        if (nextField) {
          pairs.push([currentField, nextField]);
          i = j + 1; // Move the index forward by 2 (since we've used fields[i] and fields[j])
        } else {
          pairs.push([currentField]); // If no valid pair, add the current field alone
          i++; // Move forward by 1 since there's no pair
        }
      }

      return pairs;

    }
  // pairedFields() {
  // const pairs = [];
  // const fields = this.stepProgram.fields;
  // let i = 0;
  // // cf 0 0 j 1
  // //
  // //
  // //
  // //
  // //
  // //
  // //
  // //
  // //
  
  // while (i < fields.length) {
  //   // Skip the field if `on` is false
  //   if (!fields[i].on) {
  //     i++;
  //     continue;
  //   }

  //   const currentField = { ...fields[i], originalIndex: i };

  //   const isSeparatedRow =
  //     (currentField.type === 'mark') ||
  //     (currentField.type === 'fieldRadioButtons' && currentField.settings.inSeparatedRow) ||
  //     (currentField.type === 'fieldFile' && currentField.settings.inSeparatedRow) ||
  //     (currentField.type === 'checkbox' && currentField.settings.inSeparatedRow);

  //   if (isSeparatedRow) {
  //     pairs.push([currentField]);
  //     i++;
  //     continue;
  //   }

  //   // Look for the next valid field to pair with
  //   let nextField = null;
  //   let j = i + 1;

  //   while (
  //     j < fields.length &&
  //     (
  //       !fields[j].on ||
  //       (fields[j].type === 'fieldRadioButtons' && fields[j].settings.inSeparatedRow) ||
  //       (fields[j].type === 'fieldFile' && fields[j].settings.inSeparatedRow) ||
  //       (fields[j].type === 'checkbox' && fields[j].settings.inSeparatedRow)
  //     )
  //   ) {
  //     j++;
  //   }

  //   if (j < fields.length && fields[j].on) {
  //     nextField = { ...fields[j], originalIndex: j };
  //   }

  //   if (nextField) {
  //     pairs.push([currentField, nextField]);
  //     i = j + 1;
  //   } else {
  //     pairs.push([currentField]);
  //     i++;
  //   }
  // }

  // return pairs;
// }

  },
  methods: {
    handleChange(event, index) {
      let value = event
      this.$emit('changed', {value, index});
    }
  }
}
</script>

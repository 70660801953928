<template>
  <div id="app">
    <install></install>
    <router-view />
  </div>
</template>

<script>
import install from '@/components/install'
import numeral from 'numeral'
import jwt from 'jsonwebtoken'

export default {
  name: 'app',
  components: {
    install
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      console.log('cambio route')
      console.log("to.params.productcode: ",to.params.productcode);
      console.log(to.path)
      this.$store.commit('SET_PRODUCTCODE', { productcode: to.params.productcode });
      // eslint-disable-next-line no-undef
      gtag('event', 'screen_view', {
        'screen_name': to.path,
        'logged': this.$store.getters.logged,
        'userCategory': this.$store.getters.logged && this.$store.state.userprofile.Categoria__c ? this.$store.state.userprofile.Categoria__c : 'unknown'
      })
      // eslint-disable-next-line no-undef
      gtag('pageview', to.path, { 'send_to': 'gtag' })
      // eslint-disable-next-line no-undef
      gtag('pageview', to.path, { 'send_to': 'webapp' })
      // eslint-disable-next-line no-undef
      gtag('pageview', to.path, { 'send_to': 'aicod' })
      // const step = parseInt(to.params.step, 10);
      // const currentStep = this.$store.state.step; // Get current step from Vuex
      // if (step > currentStep + 1) {
      //   console.log("i am in if ");
      //   // Prevent skipping steps
      //   this.$router.push({ path: `/candidatura1/${to.params.productcode}/${currentStep}` });
      // } else {
      //   console.log("i am in else ");
      //   this.$router.push({ path: `/candidatura1/${to.params.productcode}/${step}` });
      // }
      // console.log(from)
      // console.log(to)
    }
  },
  created: async function () {
    numeral.register('locale', 'it', {
      delimiters: { thousands: '.', decimal: ',' },
      currency: { symbol: '€' }
    })

    
    numeral.locale('it')
    // this.$i18n.locale = 'it'

    this.$store.commit('SET_MAINMENU', [
      { title: 'HOME', path: '' },
      { title: 'INFO GENERALI', path: 'Info' },
      { title: 'AREA CANDIDATURA', path: 'Candidati' },
      { title: 'MY ALMA LIFE', path: 'MyAlmaLife' },
      //{ title: 'ASSISTENZA',       path: 'Interventi' } // path: 'Assistenza'
      //{title: 'SHOP',             path: 'EcommerceHome'}
    ])

  
    // if (this.$route.query.user) {
    //   console.log('Mounted user:', this.$route.query.user);
    //   this.$store.commit('RESET_USER_DATA');

    //   let user = null;

    //   try {
    //     user = JSON.parse(decodeURIComponent(this.$route.query.user));
    //     console.log('Decoded user:', user);

    //     if (user) {
    //       const decodedToken = jwt.decode(user.token);
    //       console.log('Decoded JWT Token:', decodedToken);

    //       let connection = {
    //         username: user.username,
    //         userdata: user.token,
    //       };

    //       this.$store.commit('SET_CONNECTION', connection);
    //       console.log('Connection:', this.$store.state.connection);

    //       this.$store.dispatch('loginbyid').then(() => {
    //         this.$store.dispatch('getProfile').then(() => {
    //           console.log("Profile retrieved successfully");
    //         });
    //       })
    //     }
    //   } catch (error) {
    //     console.error("Error decoding user:", error);
    //   }
    // }
    //asd 
    const lang = navigator.language;
    this.$i18n.locale = lang.substr(0, 2) === 'it' ? 'it' : 'en';
    
    if (this.$route.query.user) {
      
      console.log("MAIN IF");
      console.log('mounted user docente:', this.$route.query.user)
      this.$store.commit('RESET_USER_DATA')
      let user = jwt.decode(this.$route.query.user)
      console.log('user', user)
      let connection = {
        username: user.username,
        userdata: user.token
      }
      this.$store.commit('SET_CONNECTION', connection)
      console.log('connection', this.$store.state.connection)
      await this.$store.dispatch('loginbyid')
      await this.$store.dispatch('getProfile')
      await this.$store.dispatch('getCampi', ['Lead', 'Account', 'Opportunity','Nazione__c']);
      // login federata for candidatura
      if (user.candidaturaStepToGoAfterLogin) {
        this.$store.commit('SET_PICKLISTREADY',true);
        this.$store.commit('SET_ISPROVIDERLOGIN',true)
        this.$router.push({
          name: 'candidatura',
          params: { productCode: this.$store.getters.getproductcode, step: user.candidaturaStepToGoAfterLogin }
        });
        let a = {};
        a.Candidatura_Current_Step__c = 1;
        a.Product_Code__c = this.$store.getters.getproductcode;
        this.$store.commit('SET_STEP', { step: 1 });
        await this.$store.dispatch('updateAccInfo', { account: a, crypt: this.$store.state.connection.userdata, username: this.$store.state.connection.username });
        if (this.$store.state.userprofile.Lingua__c) {
          console.log('set personal language to: ' + this.$store.state.userprofile.Lingua__c);
          this.$i18n.locale = this.$store.state.userprofile.Lingua__c;
        }
      }

    } else {
      console.log("MAIN ELSE");
      
      this.$store.commit('GET_USER_DATA');

      const { respdata } = this.$route.query;
      let parsedRespData = null;

      if (respdata) {
        try {
          parsedRespData = JSON.parse(decodeURIComponent(respdata));
          console.log('Parsed respdata:', parsedRespData);
        } catch (error) {
          console.error('Error parsing respdata:', error);
        }
      }

      // Function to continue with the login and profile setup
      const continueWithLogin = () => {
        this.$store.dispatch('loginbyid').then(() => {
          this.$store.dispatch('getProfile').then(() => {
            this.$store.dispatch('getCampi', ['Lead', 'Account', 'Opportunity','Nazione__c']).then(()=>{
              this.$store.commit('SET_PICKLISTREADY',true);
            })

            
            if (this.$store.state.userprofile.Lingua__c) {
              console.log('set personal language to: ' + this.$store.state.userprofile.Lingua__c);
              this.$i18n.locale = this.$store.state.userprofile.Lingua__c;
            }
            if (this.$store.getters.studente) {
              console.log('get housing');
              this.$store.dispatch('getHousing');
            }
            this.$store.dispatch('getOrdiniEcommercePronti');
          });

        });
      };

      if (parsedRespData) {
        const data = {
          username: parsedRespData.username,
          token: parsedRespData.token,
          userdata: parsedRespData.userdata,
        };

        this.$store.dispatch('setConnectionWithcontextCommit', data).then(() => {
          continueWithLogin();
        });
      } else {
        continueWithLogin();
      }
    }
  },
  mounted: function () {
    // eslint-disable-next-line no-undef
    gtag('event', 'screen_view', {
      'screen_name': this.$route.path,
      'logged': this.$store.getters.logged,
      'userCategory': this.$store.getters.logged && this.$store.state.userprofile.Categoria__c ? this.$store.state.userprofile.Categoria__c : 'unknown'
    })
  },
  methods: {
  },

}
</script>

<style></style>
<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu"></navbar>
    <herobig v-bind:title="$t('title')"></herobig>
    <boxcontainer>
      <div class="row">
        <div class="col">
          <div class="row justify-content-center">
            <div class="col">
              <h2 class="text-center"><b>{{$t('subtitleCandidatura')}}</b></h2>
              <progressbar v-if="getStep > 0" :currentStep="getStep" :maxstep="maxstep"></progressbar>

              <div v-if="getStep == 0 && showSteps != true">

                <div class="mb-4 row">
                  <p class="col-sm-12 col-md-12">
                    {{ $t('caroUtente') }},
                    <br />{{ $t('avvisoInizioProcessoCandidatura') }}
                  </p>
                  <div class="col-sm-12 col-md-12">
                    <a :href="this.$i18n.locale == 'it' ? 'https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/1t000000PReS/wkclIB1E1cOFSJCT327kteJ13XtPPLW3vDsUauSmtWw' : 'https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/5J000000UJhs/vcWmCsvxwJsjskk84N1Hx5NOJPbMfZLWOJ62Mjy5v2k'"
                      target="_blank">
                      <button class="btn btnBlue float-right" type="button">{{ $t('SCARICAMODELLOCV') }}</button>
                    </a>
                  </div>
                </div>

                <div class="d-flex flex-column align-items-center row">
                  <div class="col-10 col-md-4 mb-2">
                    <a @click.prevent="handleProviderSignIn('google', true,1)" :class="{'pe-none': disableLinks }">
                      <button class="btn btnBlue btn-block"><i class="bi bi-google mr-2"></i>{{ $t('accediGoogle') }}</button>
                    </a>
                  </div>
                  <div class="col-10 col-md-4">
                    <a @click.prevent="handleProviderSignIn('apple', true,1)" :class="{'pe-none': disableLinks }">
                      <button class="btn btnBlue btn-block"><i class="bi bi-apple mr-2"></i>{{ $t('accediApple') }}</button>
                    </a>
                  </div>
                  <div class="col-10 col-md-4 mb-2 ml-4 my-3">
                    <a @click.prevent="showStepForPlainLogin()" :class="{'pe-none': disableLinks }" style="text-decoration: underline">{{ $t('creaAccount') }}</a>
                  </div>
                </div>
              </div>

              <div v-if="showSteps || getStep > 0">
                <fieldset2 :stepProgram="steps[getStep]" :step="getStep" @changed="changedField"></fieldset2>
                <button v-if="getStep != 6" class="btn btnBlue float-right" type="button" v-on:click.prevent="next"
                  v-bind:disabled="!isStepAcceptable || isLoading">
                  {{ isLoading ? $t('Loading...') : (getStep != 5 ? $t('Successivo') : $t('Fine')) }}
                  <!-- {{ (getStep != 5) ? $t('Successivo') : $t('Fine') }} -->
                </button>
                <button v-if="getStep == 6" class="btn btnBlue float-right" type="button" v-on:click.prevent="gotoHome"
                  v-bind:disabled="!isStepAcceptable">
                  {{ $t('gotoHome') }}
                </button>

                <button v-if="getStep != 6" class="btn btnBlue float-right" type="button" v-on:click.prevent="prev" :disabled="isLoading">
                  {{ $t('Indietro')}}
                </button>

              </div>
            </div>

          </div>
        </div>
      </div>
    </boxcontainer>
    <footerline></footerline>

  
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from '@/components/navbar.vue'
import herobig from '@/components/herobig.vue'
import fieldset2 from '@/components/fieldset2.vue'
import { stepsData } from '@/assets/steps.js';
import boxcontainer from '@/components/boxcontainer.vue'
import footerline from '@/components/footerline.vue'
import progressbar from '@/components/progressbar.vue'
import moment from 'moment'



export default {
  name: 'Candidatura1',
  components: {
    fieldset2,
    boxcontainer,
    navbar,
    herobig,
    footerline,
    progressbar
  },
  props: {
    productcode: String,
    step: [String,Number]
  },
  data: function () {
    return {
      
      isLoading: false,
      showSteps: false,
      isUserData: false,
      bloccochiusura: true,
      userData: {},
      regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      maxstep: 5,
      steps: stepsData,
      scuoleFilt: [],
      scuole: [],
      lregioni: [],  
      ProvComuneArray: [],
      corso: '',
      edizione: '',
      RegioneProv: new Map(),
      ProvComune: new Map(),
      fieldMap: new Map(),
      fieldMapSalesforce: new Map(),
      acc: {},
      disableLinks: false,

      prefissi: [],
    };
  },
  watch: {
    picklistReady: async function (newVal, oldVal) {
      console.log("watched", oldVal, newVal);
      var queryFields = this.steps[1].fields[4].queryFields
      this.$store.commit('CLEAR_PREFISSI_TELEFONO')
      const result = await this.$store.dispatch('search_records', { object: queryFields.obj, properties: queryFields.fields });
      this.$store.commit('SET_PREFISSI_TELEFONO', result);

      this.initPicklistOptions();

    },
    '$i18n.locale': async function (newLocale, oldLocale) {
    console.log("Language changed", oldLocale, newLocale);
    var queryFields = this.steps[1].fields[4].queryFields
    this.$store.commit('CLEAR_PREFISSI_TELEFONO')
    const result = await this.$store.dispatch('search_records', { object: queryFields.obj, properties: queryFields.fields });
    this.$store.commit('SET_PREFISSI_TELEFONO', result);
    this.initPicklistOptions();
    },
    step: async function (newVal, oldVal){
      console.log("step watched", oldVal, newVal);
      if(oldVal==undefined && newVal==0) {this.initializeComponent()}
      if(this.acc?.Id) await this.handleCandidaturaState(this.acc);
    },
    isProviderLogin: function (newVal, oldVal) {
    console.log("isProviderLogin watched", oldVal, newVal);
    if (newVal) {
      this.prepareFormInCaseProvider();
      this.doRules()
    }
  }

  },
  computed: {
    campi() {
      return this.$store.state.campi;
    },
    isProviderLogin(){
      return this.$store.getters.isProviderLogin
    },
    picklistReady() {
      return this.$store.state.picklistReady;
    },
    // use it in do rules
    isStepAcceptable() {
      return this.isFormAcceptable;
    },
    isFormAcceptable() {
      let isAcceptable = true;
      for (let i = 0; i < this.steps[this.getStep].fields.length && isAcceptable; i++) {
        isAcceptable = this.isStepFieldValid(this.steps[this.getStep].fields[i]);
      }
      return isAcceptable;
    },
    getStep() {
      return Number(this.step)
    },
    getCurrentFields() {
      return this.steps[this.step].fields
    },
    getCurrentValues() {
      var map = new Map()
      for(var f of this.getCurrentFields) {
        map.set(f.fieldName, f.value)
      }
      return map
    }
  },
  created() {
    // console.log('installa evento');
    // window.addEventListener('beforeunload', this.handleBeforeUnload);
    // this.$store.commit('SET_ROUTERLOCK', true);
    console.log("CREATED Candidatura1.vue");
    
    this.initializeFieldMaps()
  },
  async mounted() {
    console.log("MOUNTED Candidatura1.vue");
    this.disableLinks=false
    // TODO 
    if(this.step==undefined){
      this.gotostep(0)
      console.log("this.step: ",this.step);
      return
    }
    // init store ProductCode
    this.splitProductCodeIntoCorsoAndEdizione()
    this.putCourseAndEditionAndProductcodeToStore()
    // this only gets acc and sets it to step 
    this.acc = await this.getUserData()
    console.log("this.acc: ",this.acc);
    
    if (this.acc === null) {
      this.acc = {};  // Default to an empty object or use previous value
      console.warn("User data is null. Keeping acc as empty object.");
    }
    // this function will handle all cases in machine state
    await this.handleCandidaturaState(this.acc);

    
    // for picklists
    const resp = await this.$store.dispatch('getScuole');
    if (resp.length > 0) {
      this.scuole = resp;
    }
    await this.initializeRegProv();

    // prefissi telefono
    // TODO: da generalizzare, ora specifico per prefisso telefono    
    this.doRules();
  },
  methods: {
    async initializeComponent(){
    console.log("initializeComponent");  
    // init store ProductCode
    this.splitProductCodeIntoCorsoAndEdizione()
    this.putCourseAndEditionAndProductcodeToStore()
    // this only gets acc and sets it to step 
    this.acc = await this.getUserData()
    console.log("this.acc: ",this.acc);
    
    if (this.acc === null) {
      this.acc = {};  // Default to an empty object or use previous value
      console.warn("User data is null. Keeping acc as empty object.");
    }
    // this function will handle all cases in machine state
    await this.handleCandidaturaState(this.acc);

    // for picklists
    const resp = await this.$store.dispatch('getScuole');
    if (resp.length > 0) {
      this.scuole = resp;
    }
    await this.initializeRegProv();

    // prefissi telefono
    // TODO: da generalizzare, ora specifico per prefisso telefono    
    this.doRules();
    },
    handleBeforeUnload(event) {
      if (this.$store.getters.isProviderLogin) {
        return undefined;
      }
      this.chiusura(event);
      event.returnValue = ''; 
    },
    prepareFormInCaseProvider() {
      console.log("prepareFormInCaseProvider");
      console.log(this.steps[1].fields);
      
      this.steps[1].fields[8].settings.isDisabled = true
      this.steps[1].fields[8].label = "ProviderEmail"
      this.steps[1].fields[8].value = this.$store.state.connection.username
      this.steps[1].fields[10].on = false;


      this.$store.commit('SET_ISPROVIDERLOGIN', false)
    },
    setAccData(acc) {
      console.log("setAccData");
      for (const key in acc) {
        const field = this.getFieldSalesforce(key);
        if (field) {
          field.value = acc[key];
        }
      }
    },
    async handleCandidaturaState(acc) {
      console.log("handleCandidaturaState");

      const productCode = acc?.Product_Code__c;
      const previousStepSF = acc?.Candidatura_Current_Step__c;
      const userLogged = this.$store.getters.isloggedNew;
      const ProductCodeStore = this.$store.state.productcode;
      const userWithoutProduct = !productCode;
      const currentUserStep = this.getStep;

      console.log("this.$store.state.productcode: ", ProductCodeStore);
      console.log("productCode,previousStepSF:", productCode, previousStepSF);
      console.log("this.$store.getters.isloggedNew: ", userLogged);
      console.log("this.getStep: ", currentUserStep);
      console.log("userWithoutProduct: ", userWithoutProduct);
      if (!userLogged && currentUserStep > 0) { // CASE 1
        console.log("USER LOGGED FALSE");
        console.log("CASE 1: User is not logged in but step > 0, pushing user to step 0");
        this.gotostep(0)
        return
      }
      if (userLogged) {
        console.log("USER LOGGED TRUE");
        if (userWithoutProduct) { // CASE 2
          console.log("CASE 2: User has no product, setting product and going to step 1");
          let a = {};
          a.Candidatura_Current_Step__c = 1;
          this.acc.Candidatura_Current_Step__c = 1
          a.Product_Code__c = ProductCodeStore;
          this.acc.Product_Code__c = ProductCodeStore
          this.$store.commit('SET_PRODUCTCODE', { productcode: ProductCodeStore });
          this.$store.commit('SET_STEP', { step: 1 });
          await this.$store.dispatch('updateAccInfo', { account: a, crypt: this.$store.state.connection.userdata, username: this.$store.state.connection.username });
          this.gotostep(1)
          return
        }
        if (productCode === ProductCodeStore) { // CASE 3
          console.log("CASE 3: User has correct product");

          if (!previousStepSF) { // CASE 7
            console.log("CASE 7: previousStepSF is undefined, going to step 1");
            let a = {};
            a.Candidatura_Current_Step__c = 1;
            this.acc.Candidatura_Current_Step__c = 1
            this.$store.commit('SET_STEP', { step: 1 });
            await this.$store.dispatch('updateAccInfo', { account: a, crypt: this.$store.state.connection.userdata, username: this.$store.state.connection.username });
            this.gotostep(1)
            return
          }
          if (previousStepSF === 6 && currentUserStep != 6) { // CASE 8/11
            console.log("CASE 8/11: User's getStep is not 6 but previousStepSF is 6, going to step 6");
            this.gotostep(6)
            return
          }
          if (previousStepSF != currentUserStep) {// CASE 10/7
            if (currentUserStep==previousStepSF+1) {
              console.log("do nothing in this case");
              return
            }
            if (currentUserStep < previousStepSF) {
              console.log("CASE 10/7.2: User's getStep is different and is <, going to currentUserStep");
              this.gotostep(currentUserStep)
              return
            }
            console.log("CASE 10/7.1: User's getStep is different, going to previousStepSF");
            this.gotostep(previousStepSF)
            return
          }
          //CASE 9/12
          // 9: currentStep == getStep
          // 12: currentUserStep = 6 && currentStep = 6
          console.log("CASE 9/12: doing nothing");
          return

        }
        else { // CASE 4/5/6
          // so if product not equal so we can know it only when user logged we change product and push to 1 
          console.log("CASE 4/5/6: User had a product but changed it, setting new product and going to step 1");
          let a = {};
          a.Product_Code__c = ProductCodeStore;
          this.acc.Product_Code__c = ProductCodeStore
          a.Candidatura_Current_Step__c = 1;
          this.acc.Candidatura_Current_Step__c = 1
          this.$store.commit('SET_PRODUCTCODE', { productcode: ProductCodeStore });
          this.$store.commit('SET_STEP', { step: 1 });
          await this.$store.dispatch('updateAccInfo', { account: a, crypt: this.$store.state.connection.userdata, username: this.$store.state.connection.username });
          this.gotostep(1)
          return
        }
      }
    },
    async getAccDataFromSalesforce() {
      let fields = this.extractSalesforceFieldNames();

      let resp = await this.$store.dispatch('getAccData', { username: this.$store.state.connection.username, crypt: this.$store.state.connection.userdata, callname: 'getAccData', fields: fields });

      console.log("myAccData: ", resp);
      return resp;
    },
    async getUserData() {
      console.log("getUserData");
      await this.$store.dispatch('loginbyid');
      let acc = await this.getAccDataFromSalesforce();

      if (acc && typeof acc === "object") {
        if (acc.isAltreScuole__c) {
          acc.isAltreScuole__c = acc.isAltreScuole__c ? "Si" : "No";
        }

        if (acc.PersonEmail) {
          this.steps[1].fields[10].on = false;
        }

        if (acc.LastName && acc.Candidatura_Current_Step__c<1 && acc.LastName==="lastname" ) {
          acc.LastName = "";
        }

        if (acc.PersonMobilePhone) {
          const parts = acc.PersonMobilePhone.trim().split(" ");
          if (parts.length === 2) {

            this.getField("prefissoCellulareField").value = parts[0].replace("+", "");
            this.getField("cellulareField").value = parts[1];
            acc.PersonMobilePhone=parts[1]
          }
        }

        if (acc.Phone_2__c) {
          const parts2 = acc.Phone_2__c.trim().split(" ");
          if (parts2.length === 2) {
            
            this.getField("prefissoCellulare2Field").value = parts2[0].replace("+", "");
            this.getField("altroCellulareField").value = parts2[1];
            acc.Phone_2__c=parts2[1]
          }
        }
        console.log("acc: ",acc);
        
        this.setAccData(acc); 
      } else {
        console.error("Error: getAccDataFromSalesforce() returned null or undefined", acc);
      }

      return acc;
    },

    showStepForPlainLogin() {
      this.showSteps = true
    },
    splitProductCodeIntoCorsoAndEdizione() {
      var prod = this.$route.params.productcode;
      console.log("this.$route.params: ", this.$route.params);

      console.log('prod', prod);
      this.corso = prod.split('-')[0];
      this.edizione = prod.split('-')[1];
      console.log('this.corso', this.corso);
      console.log('this.edizione', this.edizione);

      this.$store.commit('SET_STEP', { step: this.$route.params.step })
    },
    putCourseAndEditionAndProductcodeToStore() {
      this.$store.commit('SET_PRODUCTCODE', { productcode: this.$route.params.productcode });
      this.$store.commit('SET_COURSE', { course: this.corso });
      this.$store.commit('SET_EDITION', { edition: this.edizione });
    },
    // TODO
    insertStepIntoObject(obj, step) {
      let fields = this.steps[step].fields;
      for (const field of fields) {
        if (field.on && field.salesforceFieldName) {
          if (!obj[field.salesforceFieldName]) {
            obj[field.salesforceFieldName] = field.value;
          }
        }
        // if (field.salesforceFieldName) {
        //   obj[field.salesforceFieldName] = field.value;
        // } 
      }

    },
    // 1 create and login 
    async createAndLogin(username, firstname, lastname, password) {
      try {
        var a = {};
        a.Username__pc = username;
        a.LastName = lastname || "lastname";
        a.Password__pc = password;
        a.Lingua__c = this.$i18n.locale.toLowerCase()

        console.log("Account before createAndLogin", a);

        await this.$store.dispatch("createAndLogin", { account: a });
        this.acc = await this.getUserData();
        // await this.handleCandidaturaState(acc);
      } catch (error) {
        console.error("Error in createAndLogin:", error);
      } finally {
        this.isLoading = false; // Ensures loading stops even if an error occurs
      }
    },

    checkField(fieldName){
      const field = this.getField(fieldName);
      return field && field.on !== false ? field.value : undefined;
    },

    async updateAccInfo() {
      this.isLoading = true; 
      try {
        var a = {};

        if (this.getStep == 1) {
          this.insertStepIntoObject(a, this.getStep);
          a.PersonMobilePhone="+"+this.getField("prefissoCellulareField").value+" "+this.getField("cellulareField").value.trim();
          if(this.getField("altroCellulareField").value != "") a.Phone_2__c="+"+this.getField("prefissoCellulare2Field").value+" "+this.getField("altroCellulareField").value.trim();
        }
        if (this.getStep == 2) {
          this.insertStepIntoObject(a, this.getStep);
          var toUTC = new Date(this.getField("datadiNascitaField").value).toUTCString();
          a.Data_di_nascita__c = moment(toUTC).unix() * 1000;
          a.PersonMailingState = this.$i18n.locale == 'it' ? this.getField("provinciadiResidenzaField").value : 'EE - For Foreign Country';
          a.Provincia_di_Nascita__c = this.$i18n.locale == 'it' ? this.getField("provinciadiNascitaField").value : 'EE - For Foreign Country';
          a.Cliente_senza_CF_Italiano__c = this.getField("haiCFField").value == 'No';
          if (!a.Cliente_senza_CF_Italiano__c && this.getField("codiceFiscaleField").value !== '') a.CF__c = this.getField("codiceFiscaleField").value;
          if (this.getField("haiCFField").value == 'YES' || this.$i18n.locale == 'it') a.CF__c = this.getField("codiceFiscaleField").value;
          if (this.$i18n.locale == 'en') a.Cliente_senza_CF_Italiano__c = this.getField("haiCFField").value == 'No';
        }

        if (this.getStep == 3) {
          this.insertStepIntoObject(a, this.getStep);
          
          if(this.checkField("frequentatoAlmaField")){
            a.isAltreScuole__c = this.getField("frequentatoAlmaField").value == 'Si'

          }
          if(this.checkField("qualificaoTitolodiStudioField")){
            a.Titolo_di_studio__c = this.getField("qualificaoTitolodiStudioField").value;

          }
          if(this.checkField("indirizzodiStudioField")){
            a.Indirizzo_titolo__c = this.getField("indirizzodiStudioField").value

          }
          if(this.checkField("altroConosciutoAlmaField")){
            a.Altro_conosciuto_Alma__c = this.getField("altroConosciutoAlmaField").value
          }
         
          const fileFieldValue = this.getField("uploadEnglishCertificateField").value;
          if (fileFieldValue) {
            try {
              const fileObj = JSON.parse(fileFieldValue);
              await this.uploadDoc(fileObj.name, fileObj.fileData, null, 'Certificato Lingua', 'Certificato Lingua Inglese');
            } catch (error) {
              console.error("Error parsing file JSON:", error);
            }
          }
        }

        if (this.getStep == 4) {
          const fileObj = JSON.parse(this.getField("caricaCurriculumField").value);
          await this.uploadDoc(fileObj.name, fileObj.fileData, null, 'CV', 'CV');
          if(this.getField("caricaMotivationalLetterField").value){
            const fileMotivationalLetter = JSON.parse(this.getField("caricaMotivationalLetterField").value);
            await this.uploadDoc(fileMotivationalLetter.name, fileMotivationalLetter.fileData, null, 'Motivational Letter', 'Lettera motivazionale');
          }
        }

        if (this.getStep == 5) {
          a.Tipo_Documento__c = this.getField("tipoDocumentoField").value; 
          a.Numero__c = this.getField("numeroField").value;

          var toUTCdtril = new Date(this.getField("dataRilascioField").value).toUTCString();
          var toUTCdtScad = new Date(this.getField("dataScadenzaField").value).toUTCString();
          a.Data_Rilascio__c = moment(toUTCdtril).unix() * 1000;
          a.Data_Scadenza__c = moment(toUTCdtScad).unix() * 1000;

          

          if (this.checkField("inserisciEnteRilascioField")) {
            if (this.getField("inserisciEnteRilascioField").value != '') {
              if (this.getField("nazioneRilascioField").value.includes('Italia')) {
                if (this.getField("tipoDocumentoField").value.includes("Carta d'identità")) {
                  a.Ente_Rilasciato__c = "Comune di " + this.getField("comunedirilascioField").value;
                } else if (this.getField("tipoDocumentoField").value.includes('Patente')) {
                  a.Ente_Rilasciato__c = "Motorizzazione Civile di " + this.getField("comunedirilascioField").value;
                } else {
                  throw new Error('Ente non valido');
                }
              } else {
                a.Ente_Rilasciato__c = this.getField("inserisciEnteRilascioField").value;
              }
            } else {
              if (this.getField("tipoDocumentoField").value.includes("Carta d'identità")) {
                a.Ente_Rilasciato__c = "Comune di " + this.getField("comunedirilascioField").value;
              } else if (this.getField("tipoDocumentoField").value.includes('Patente')) {
                a.Ente_Rilasciato__c = "Motorizzazione Civile di " + this.getField("comunedirilascioField").value;
              } else if (this.getField("tipoDocumentoField").value.includes('Passaporto')) {
                a.Ente_Rilasciato__c = "Ministro Affari Esteri";
              } else {
                throw new Error('Ente non valido');
              }
            }
          }

        
          var comuneName = this.getField("comunedirilascioField").value;
          var statoRilascioName = this.getField("nazioneRilascioField").value;
          var provinciaName = this.getField("provinciadelcomunedirilascioField").value;

          if (comuneName === 'comuneNotFound') comuneName = '';

          try {
            const resp = await this.$store.dispatch('getIdComuneStatoRilascio', {
              comuneName,
              statoName: statoRilascioName,
              provinciaName: provinciaName
            });

            a.Stato_di_Rilascio__c = resp[statoRilascioName];
            a.Comune_di_Rilascio__c = resp[comuneName];

            const fileObj = JSON.parse(this.getField("caricaDocumentoIdentitaField").value);
            await this.uploadDoc(fileObj.name, fileObj.fileData, moment(toUTCdtScad).unix() * 1000, a.Tipo_Documento__c, a.Numero__c);

            await this.$store.dispatch('updateAccInfo', {
              account: a,
              crypt: this.$store.state.connection.userdata,
              username: this.$store.state.connection.username
            });
          } catch (error) {
            console.error('Error updating account info:', error);
          }
        }

        a.Language__c = this.$i18n.locale;
        a.Lingua__c = this.$i18n.locale.toLowerCase();

        a.Status__c = 'Candidato';

        if (this.$store.state.userprofile.Id) {
          a.Id = this.$store.state.userprofile.Id;
        } else {
          a.AccountSource = 'Form Candidatura';
        }

        if (this.getStep == 0) {
          this.insertStepIntoObject(a, 0);
        }
        
        a.Product_Code__c = this.$store.state.productcode;
        this.acc.Product_Code__c = a.Product_Code__c
        a.Candidatura_Current_Step__c = this.getStep + 1;
        this.acc.Candidatura_Current_Step__c = a.Candidatura_Current_Step__c;

        console.log('Account before updateAccInfo', a);
        await this.$store.dispatch('updateAccInfo', {
          account: a,
          crypt: this.$store.state.connection.userdata,
          username: this.$store.state.connection.username
        });

      } catch (error) {
        console.error("Error in updateAccInfo:", error);
      } finally {
        this.isLoading = false; 
      }
    },
    normalizePicklistValue(value) {
      if (value.includes('_')) {
        return value.split('_').slice(-1)[0];
      }
      return value;
    },
    async uploadDoc(filename, payload, scadenza = null, tipo, name) {
      let data = {
        username: this.$store.state.connection.username,
        crypt: this.$store.state.connection.userdata,
        filename: filename,
        tipo: tipo,
        name: name,
        payload: payload
      };

      if (scadenza !== null) {
        data.scadenza = scadenza;
      }

      const resp = await this.$store.dispatch('uploadDoc', data);
      console.log(resp);
    },
    async createOppty() {
      try {
        const resp = await this.$store.dispatch('createOppty', {
          username: this.$store.state.connection.username,
          crypt: this.$store.state.connection.userdata,
          oppty: { Name: 'portaleCandidature' },
          corso: this.corso,
          edizione: this.edizione
        });

        console.log('createOppty:', resp);
      } catch (error) {
        console.error('Error in createOppty:', error);
      }
    },

    // helper func for CreateAndLogin ????
    async callCreateAndLogin() {
      await this.$store.dispatch('loginbyid');
      this.createAndLogin(
        this.getField("USERNAMEField").value,
        "",
        "",
        this.getField("PASSWORDField").value
      );
    },

    initializeFieldMaps() {
      console.log("initializeFieldMap");
      for (const step of this.steps) {
        for (const field of step.fields) {
          this.fieldMap.set(field.fieldName, field);
          this.fieldMapSalesforce.set(field.salesforceFieldName, field);
        }
      }
    },
    getField(fieldName) {
      return this.fieldMap.get(fieldName);
    },
    getFieldSalesforce(fieldSalesforceName) {
      return this.fieldMapSalesforce.get(fieldSalesforceName);
    },
    prev: function () {
      let nextStep = this.getStep - 1
      if(nextStep<0) nextStep=0
      this.$store.commit('SET_STEP', { step: nextStep });
      this.gotostep(nextStep)
    },
    next: async function () {
      this.isLoading = true
      this.doRules()
      let currentStep = this.getStep
      let newStep = currentStep + 1;
      if(!this.isStepAcceptable){
        this.isLoading = false
        return;
      }
      if (!this.getStep) {
        await this.callCreateAndLogin()
      }
      else {
        if(!(newStep>5)) // HACK for correct loadaer
          await this.updateAccInfo()
      }
      //finish
      if (newStep > 5) {
        await this.updateAccInfo()
        await this.createOppty()
        this.gotostep(newStep)
        var data2 = { username: this.$store.state.connection.username, crypt: this.$store.state.connection.userdata };
        await this.$store.dispatch('getProfile', data2)
        this.isLoading = false
        return 
      }
      this.$store.commit('SET_STEP', { step: newStep });
      this.gotostep(newStep)
    },

    initPicklistOptions() {
      console.log("initPicklistOptions");

      // TODO
      // console.log("PREFISSO: ",this.getPicklistValues("Nazione__c","Prefisso_internazionale__c"));
      
      // this.steps[1].fields[4].options = this.getPicklistValues("Nazione__c","Prefisso_internazionale__c")
      // this.steps[1].fields[6].options = this.getPicklistValues("Nazione__c","Prefisso_internazionale__c")

      this.steps[1].fields[4].options = this.setPicklistFromSFQuery(this.$store.state.prefissiTelefono, this.steps[1].fields[4].queryFields)
      this.steps[1].fields[6].options = this.setPicklistFromSFQuery(this.$store.state.prefissiTelefono, this.steps[1].fields[6].queryFields)

      this.steps[2].fields[4].options = this.getPicklistValues('Lead', 'Provincia_Picklist__c');
      this.steps[2].fields[5].options = this.getPicklistValues('Lead', 'Nazione_Picklist__c');
      this.steps[2].fields[10].options = this.getPicklistValues('Lead', 'Provincia_Picklist__c');
      this.steps[2].fields[11].options = this.getPicklistValues('Lead', 'Nazione_Picklist__c');
      this.steps[2].fields[13].options = this.getPicklistValues('Lead', 'Nazione_Picklist__c');


      this.steps[3].fields[0].options = this.getPicklistValues('Account', 'Titolo_di_studio__c');
      this.steps[3].fields[1].options = this.getPicklistValues('Account', 'Indirizzo_titolo__c');
      this.steps[3].fields[4].options = this.getPicklistValues('Lead', 'Nazione_Picklist__c');

      this.steps[3].fields[7].options = []
           
      this.steps[3].fields[8].options = []    
      this.steps[3].fields[9].options = []     
      this.steps[3].fields[10].options = []    
      this.steps[3].fields[14].options = this.getPicklistValues('Account', 'ConosciutoALMA__c');   
      this.steps[3].fields[16].options = this.getPicklistValues('Account', 'Livello_Inglese__c');

      this.steps[5].fields[0].options = this.getPicklistValues('Account', 'Tipo_Documento__c');
      this.steps[5].fields[4].options = this.getPicklistValues('Lead', 'Nazione_Picklist__c');
      this.steps[5].fields[6].options = this.getPicklistValues('Lead', 'Provincia_Picklist__c');
      this.steps[5].fields[7].options = []

    },
    assignPicklistValues() {
      if (Array.isArray(this.lregioni)) {
        let formattedOptions = this.lregioni
          .map(region => ({
            value: region.value,
            label: region.value
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        this.steps[3].fields[7].options = formattedOptions;

        const fieldValue = this.getField("regioneIstitutoField")?.value;
        if (fieldValue) {
          const result = this.lregioni.find(region => region.value === fieldValue);
          let formattedOptions = result
            ? result.label
              .map(labelItem => ({
                value: labelItem,
                label: labelItem
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
            : [];
          this.steps[3].fields[8].options = formattedOptions;
        }
      }

      if (Array.isArray(this.ProvComune)) {
        const provinciaField = this.getField("provinciaIstitutoField")?.value;
        const provinciaComuneField = this.getField("provinciadelcomunedirilascioField")?.value;

        if (provinciaField) {
          const result = this.ProvComune.find(element => element.value === provinciaField);
          let formattedOptions = result
            ? result.label
              .map(v => ({
                value: v,
                label: v
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
            : [];
          this.steps[3].fields[9].options = formattedOptions;
        }

        if (provinciaComuneField) {
          const result = this.ProvComune.find(element => element.value === provinciaComuneField);
          let formattedOptions = result
            ? result.label
              .map(v => ({
                value: v,
                label: v
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
            : [];
          this.steps[5].fields[7].options = formattedOptions;
        }
      }

      if (Array.isArray(this.scuoleFilt)) {
        const formattedOptions = this.scuoleFilt
          .map(school => ({
            value: school.Id,
            label: school.Name
          }))
          .sort((a, b) => a.label.localeCompare(b.label)); 

        this.steps[3].fields[10].options = formattedOptions;
      }
    },


    areRequiredFieldsValid(step) {
      let fields = this.steps[step].fields;
      for (let field of fields) {
        if (field.required) {
          field.isValid = field.value ? true : false
        }
      }
    },
    doRules: function () {
      console.log("doRules");
      //HACK
      if(this.getStep==3){
        this.filtraScuole()
      }
      this.assignPicklistValues()

      // IS VALID FOR FIELDS:
      this.areRequiredFieldsValid(this.getStep)
      // STEP 0:
      if (this.steps[0].fields[5].value && this.steps[0].fields[5].value === this.steps[0].fields[3].value) {
        this.steps[0].fields[5].isValid = true;
      } else {
        this.steps[0].fields[5].isValid = false;
      }
      // STEP 1:
      this.steps[1].fields[8].isValid = this.steps[1].fields[8].value && this.regEmail.test(this.steps[1].fields[8].value) ? true : false
      this.steps[1].fields[9].isValid = this.steps[1].fields[9].value && this.regEmail.test(this.steps[1].fields[9].value) ? true : false
      this.steps[1].fields[10].isValid = ((this.steps[1].fields[10].value === this.steps[1].fields[8].value) && this.steps[1].fields[8].value) ? true : false
      this.steps[1].fields[14].isValid = this.steps[1].fields[14].value !== undefined ? true : true;

      // STEP 2:
      this.steps[2].fields[2].isValid = this.steps[2].fields[2].value && this.steps[2].fields[2].value.length === 16 ? true : false;
      // STEP 3:
      this.steps[3].fields[17].isValid = false; // Default to invalid

      let fileObj0 = this.steps[3].fields[17].value ? JSON.parse(this.steps[3].fields[17].value): {};

      this.steps[3].fields[17].isValid = fileObj0.fileMB ? this.steps[3].fields[17].settings.maxMB >= fileObj0.fileMB: !!this.steps[3].fields[17].value;

      // STEP 4:
      this.steps[4].fields[0].isValid = false; // Default to invalid

      let fileObj = this.steps[4].fields[0].value ? JSON.parse(this.steps[4].fields[0].value): {};

      this.steps[4].fields[0].isValid = fileObj.fileMB ? this.steps[4].fields[0].settings.maxMB >= fileObj.fileMB: !!this.steps[4].fields[0].value;
      
      this.steps[4].fields[1].isValid = false; // Default to invalid

      let fileObjML = this.steps[4].fields[1].value ? JSON.parse(this.steps[4].fields[1].value): {};
      this.steps[4].fields[1].isValid = fileObjML.fileMB ? this.steps[4].fields[1].settings.maxMB >= fileObjML.fileMB: !!this.steps[4].fields[1].value;
      // STEP 5:
      this.steps[5].fields[9].isValid = false; // Default to invalid
      let fileObj1 = this.steps[5].fields[9].value ? JSON.parse(this.steps[5].fields[9].value) : {};
      this.steps[5].fields[9].isValid = fileObj1.fileMB ? this.steps[5].fields[9].settings.maxMB >= fileObj1.fileMB : !!this.steps[5].fields[9].value;


      // VISIBILITY
      // STEP 0:
      //rule 1: if v-if="this.$store.state.userprofile.Id"  2- 3- 4-  5+ 
      this.steps[0].fields[2].on = this.$store.state.userprofile.Id ? true : false

      this.steps[0].fields[3].on = !this.$store.state.userprofile.Id ? true : false
      this.steps[0].fields[4].on = !this.$store.state.userprofile.Id ? true : false
      this.steps[0].fields[5].on = !this.$store.state.userprofile.Id ? true : false
      // STEP 1:
      
      this.steps[1].fields[11].on = this.steps[1].fields[10].on ? true : false
      // STEP 2:
      //rule 1: if $i18n.locale == 'en' 1+
      //rule 2: if $i18n.locale == 'it' || user.hacodFisc == 'Yes' 2+
      //rule 3: if "$i18n.locale == 'it'" 4+ 10+
      this.steps[2].fields[1].on = this.$i18n.locale == 'en' ? true : false
      
      this.steps[2].fields[2].on = (this.$i18n.locale == 'it' || this.getField("haiCFField").value == 'Si') ? true : false
      this.steps[2].fields[4].on = this.$i18n.locale == 'it' ? true : false
      this.steps[2].fields[10].on = this.$i18n.locale == 'it' ? true : false

      // STEP 3
      this.steps[3].fields[1].on = false;
      this.steps[3].fields[2].on = false;
      this.steps[3].fields[3].on = false;
      this.steps[3].fields[4].on = false;
      this.steps[3].fields[5].on = false;
      this.steps[3].fields[6].on = false;
      this.steps[3].fields[7].on = false;
      this.steps[3].fields[8].on = false;
      this.steps[3].fields[10].on = false;
      this.steps[3].fields[11].on = false;
      this.steps[3].fields[12].on = true;
      this.steps[3].fields[14].on = true;
      this.steps[3].fields[16].on = false;
      this.steps[3].fields[17].on = false;

      
      this.steps[3].fields[1].on = this.getField("qualificaoTitolodiStudioField").value === 'Diploma di Scuola Superiore';
      this.steps[3].fields[2].on = this.getField("qualificaoTitolodiStudioField").value === 'Altro (specificare)' || (this.getField("indirizzodiStudioField").value === 'Altro (specificare)' && this.steps[3].fields[1].on) ? true : false;

      this.steps[3].fields[3].on = this.getField("qualificaoTitolodiStudioField").value !== 'Non ho alcun diploma di maturità' ? true : false;

      this.steps[3].fields[4].on = this.getField("qualificaoTitolodiStudioField").value !== 'Non ho alcun diploma di maturità' ? true : false;

      this.steps[3].fields[5].on = this.getField("nazioneIstitutoField").value !== 'Italia' && this.getField("qualificaoTitolodiStudioField").value !== 'Non ho alcun diploma di maturità' ? true : false;
      
      this.steps[3].fields[6].on = this.getField("nazioneIstitutoField").value !== 'Italia' && this.getField("qualificaoTitolodiStudioField").value !== 'Non ho alcun diploma di maturità' ? true : false;
      
      this.steps[3].fields[7].on = this.getField("nazioneIstitutoField").value === 'Italia' && this.getField("qualificaoTitolodiStudioField").value !== 'Non ho alcun diploma di maturità' ? true : false;

      this.steps[3].fields[8].on = this.getField("nazioneIstitutoField").value === 'Italia' && this.getField("qualificaoTitolodiStudioField").value !== 'Non ho alcun diploma di maturità' ? true : false;
      this.steps[3].fields[9].on = this.getField("nazioneIstitutoField").value === 'Italia' && this.getField("qualificaoTitolodiStudioField").value !== 'Non ho alcun diploma di maturità' ? true : false;

      // this.steps[3].fields[10].on = this.getField("qualificaoTitolodiStudioField").value==='Diploma di Scuola Superiore' && this.getField("nazioneIstitutoField").value === 'Italia' && this.getField("qualificaoTitolodiStudioField").value === 'Diploma di Scuola Superiore' && this.scuoleFilt.length > 0 && this.getField("indirizzodiStudioField").value === 'IT_Istituto Alberghiero' ? true : false;
      // // this.steps[3].fields[11].on = this.getField("qualificaoTitolodiStudioField").value!=='Diploma di Scuola Superiore' && this.getField("nazioneIstitutoField").value === 'Italia' && this.getField("qualificaoTitolodiStudioField").value !== 'Non ho alcun diploma di maturità' && (this.scuoleFilt.length === 0 || this.getField("indirizzodiStudioField").value !== 'IT_Istituto Alberghiero' ) ? true : false;
      // this.steps[3].fields[11].on = 
      const field10Condition =
        this.getField("qualificaoTitolodiStudioField").value === 'Diploma di Scuola Superiore' &&
        this.getField("nazioneIstitutoField").value === 'Italia' &&
        this.getField("indirizzodiStudioField").value === 'IT_Istituto Alberghiero' &&
        this.scuoleFilt.length > 0;

      this.steps[3].fields[10].on = field10Condition; // pick
      this.steps[3].fields[11].on = !field10Condition; // text
      this.steps[3].fields[11].on = this.getField("qualificaoTitolodiStudioField").value === 'Non ho alcun diploma di maturità'?false:!field10Condition


      this.steps[3].fields[13].on = this.getField("frequentatoAlmaField").value === 'Si' ? true : false
      this.steps[3].fields[15].on = this.getField("conosciutoAlmaField").value === 'Altro' ? true : false;
      this.steps[3].fields[16].on = this.$i18n.locale !== 'it'  ? true : false;
      this.steps[3].fields[17].on = this.$i18n.locale !== 'it' && ( this.getField("livelloIngleseField").value && this.getField("livelloIngleseField").value !== 'EN_Madrelingua') ? true : false;
      
      // STEP 4:
      this.steps[4].fields[1].on = false
      this.steps[4].fields[1].on = this.$i18n.locale !== 'it' ? true : false;
 
      // STEP 5:
      this.steps[5].fields[5].on = false
      this.steps[5].fields[6].on = false
      this.steps[5].fields[7].on = false
      this.steps[5].fields[8].on = false
      this.steps[5].fields[5].on = this.getField("nazioneRilascioField").value != '' && this.getField("nazioneRilascioField").value != 'Italia'
      this.steps[5].fields[6].on = this.getField("nazioneRilascioField").value === 'Italia' && this.getField("tipoDocumentoField").value && this.getField("nazioneRilascioField").value && this.getField("tipoDocumentoField").value !== 'Passaporto';
      this.steps[5].fields[7].on = this.getField("nazioneRilascioField").value === 'Italia' && this.getField("tipoDocumentoField").value && this.getField("nazioneRilascioField").value && this.getField("tipoDocumentoField").value !== 'Passaporto';
      
      this.steps[5].fields[8].on = this.getField("comunedirilascioField").value == 'comuneNotFound' && this.getField("nazioneRilascioField").value == 'Italia' && this.getField("tipoDocumentoField").value != 'Passaporto'
      

      // rules
      this.steps[1].fields[6].required = this.steps[1].fields[7].value?true:false

    },
    changedField(payload) {
      let value = payload.value
      let index = payload.index
      const theField = this.steps[this.getStep].fields[index];
      this.setFieldValue(theField, value);
      //on change CittàIstituto picklist
      if(index==9 && this.getStep==3)  this.filtraScuole()
      //on change email
      if(index==8 && this.getStep==1) {
        this.steps[1].fields[10].on = true
      }
      this.doRules();

    },
    setFieldValue: (field, value) => {
      field.value = value;

    },
    isStepFieldValid(field) {
      return !(field.on && field.required) || field.isValid;
    },

    // TODO here we have wrong names 
    getPicklistValues: function (o, fieldname) {
      console.log("getPicklistValues",o, fieldname);
      
      if (!this.$store.state.campi || !this.$store.state.campi.has) {
        console.warn("campi is undefined or null");
        return [];
      }

      if (!this.$store.state.campi.has(o)) {
        console.warn(`Object '${o}' not found in campi`);
        return [];
      }
      
      var obj = this.$store.state.campi.get(o);
      if (!obj.has(fieldname)) {
        console.warn(`Field '${fieldname}' not found in object '${o}'`);
        return [];
      }

      var field = obj.get(fieldname);
      let results = []
      if (!field || !field.values) return [];
      let values = field.values.split(';')
      let labels = field.labels.split(';')
      /*
        it en zh
        1 it no prefix + IT_
        2 en EN_
        3 zh EN_
      */
      for (let i = 0; i < values.length; i++) {
        if(!values[i]){
          continue;
        }
        if(values[i][2] !== '_'){ 
          if(this.$i18n.locale == "it") results.push({label: labels[i], value: values[i]})
          else results.push({label: this.$t(labels[i]), value: values[i]})
          
        } else {
          if (this.$i18n.locale == "it") {
            if (values[i].substr(0, 3) === 'IT_') results.push({ label: labels[i], value: values[i] })
          }
          else {
            if (values[i].substr(0, 3) === 'EN_') results.push({ label: this.$t(labels[i]), value: values[i] })
          }
        }
      }      
      return results

    },

    setPicklistFromSFQuery(records, queryFields) {
      
      var obj = queryFields.obj
      var fields = queryFields.fields

      var picklist = []

      //TODO: da migliorare, ora forzato per prefisso telefono
      if(obj == 'Nazione__c') {
        for(let r of records) {
          if(r[fields[0]] != null) {
            let value = '+' + r[fields[0]] +' '+ r[fields[1]]
            picklist.push({ label: this.$te(value) ? this.$t(value) : value, value: r[fields[0]] })
          }
        }
      } else {
        for(let f of fields) {
          for(let r of records) {
            let value = r[f]
            if(value != null) picklist.push({ label: this.$te(value) ? this.$t(value) : value, value: value })
          }
        }
      }

      return picklist

    },



    initializeRegProv: async function () {
      console.log("initializeRegProv");

      const regioniResult = await this.$store.dispatch('getRegioniProvincie', 'regione');
      // this.RegioneProv = Object.entries(regioniResult).map(([key, value]) => ({ label: value, value: key }));
      // this.lregioni = this.RegioneProv.map(region => region.label);
      this.lregioni = Object.entries(regioniResult).map(([key, value]) => ({ label: value, value: key }));
      this.RegioneProv = this.lregioni.map(region => region.label);
      console.log("this.lregioni.length: ",this.lregioni.length);
      

      // console.log('self.RegioneProv', this.RegioneProv);
      // console.log('self.lregioni', this.lregioni);

      const provinceResult = await this.$store.dispatch('getRegioniProvincie', 'provincia');
      this.ProvComune = Object.entries(provinceResult).map(([key, value]) => ({ label: value, value: key }));
      this.ProvComuneArray = this.ProvComune.map(provincia => provincia.label);
      console.log("this.ProvComune.length: ",this.ProvComune.length);


      // console.log('self.ProvComune', this.ProvComune);
      // console.log('self.ProvComuneArray', this.ProvComuneArray);

    },


    filtraScuole: function () {
      console.log('filtraScuole');

      this.scuoleFilt = [];
      // this.getField("istitutoFieldPick").value = '';
      // this.getField("nomeIstitutoField12").value = '';
      // this.getField("nomeIstitutoField7").value = '';
      

      
      const citta = (this.getField("cittaIstitutoFieldPick").value || this.getField("cittaIstitutoField").value || "").toLowerCase();
      const provincia = (this.getField("provinciaIstitutoField").value || "").toLowerCase();
      const regione = (this.getField("regioneIstitutoField").value || "").toLowerCase();
      this.scuoleFilt = this.scuole.filter(s =>
        s.BillingCity.toLowerCase() === citta &&
        s.BillingState.toLowerCase() === provincia &&
        s.Regione_Istituto__c.toLowerCase() === regione
      );

    },



    handleProviderSignIn: async function (provider, sendInfoPlain = false,candidaturaStepToGoAfterLogin) {
      console.log('handleProviderSignIn')
      this.disableLinks = true
      this.$store.commit('SET_ISPROVIDERLOGIN',true)
      const payload = {
        provider: provider,
        callBackPath: this.$route.path,
        sendInfoPlain: sendInfoPlain,
        candidaturaStepToGoAfterLogin: candidaturaStepToGoAfterLogin,
        lang: this.$i18n.locale.toLowerCase(),
        endpoint: "login"
      };
      await this.$store.dispatch('signInWithProvider', payload)
    },
    extractSalesforceFieldNames() {
      const salesforceFieldNames = new Set(); 
      for (const step of this.steps) {
        for (const field of step.fields) {
          if (field.salesforceFieldName) {
            salesforceFieldNames.add(field.salesforceFieldName); 
          }
        }
      }
      const productCode = "Product_Code__c";
      const currentStep = "Candidatura_Current_Step__c";
      const Id = "Id";
      const IdentityProvider = "IdentityProvider__c";
      salesforceFieldNames.add(productCode)
      salesforceFieldNames.add(currentStep)
      salesforceFieldNames.add(Id)
      salesforceFieldNames.add(IdentityProvider)

      return Array.from(salesforceFieldNames);
    },
    gotostep(step){
      this.$router.push({
          name: 'candidatura',
          params: { productCode: this.$store.getters.getproductcode, step: step }
        });
    },
    gotoHome(){
      this.$router.push({name: 'Home'});
    },
    chiusura: function(event) {
      console.log('blocco chiusura');
      if (this.bloccochiusura) {
        event.preventDefault();
        if (confirm('Si desidera uscire?')) {
          event.returnValue='';
          this.$store.commit('SET_ROUTERLOCK', false);
        } else {
          event.returnValue=true;
        }
      }
    },
  }
}
</script>
<style scoped>
</style>
<template>
  <div class="form-group">
    <label class="control-label">{{ placeholder }}</label>
    <select  
      class="form-control"
      v-bind:title="translatedLabel2"
      :class="required ? (isValid ? 'is-valid' : 'is-invalid') : ''"
      :value="fieldval"
      v-model="fieldval"
      required
      @change="notifyParent">
      <option value="" disabled selected>{{ translatedLabel2 }}</option>
      <option v-if="settings?.fallbackOption" :value="settings.fallbackOption"> {{ settings.fallbackOption }}</option>
      <option v-for="v in options" :key="fieldKey ? `${fieldKey}-${v.label}` : v.value" v-bind:value="v.value">
        {{v.label }}
      </option>
      <!-- <option v-for="v in options" :key="v.value">{{ v.label }}</option> -->
    </select>
    
    <p v-if="settings?.CommonEuropeanFramework">* {{ $t('CommonEuropeanFramework') }}</p>
  </div>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'fieldPickList',
  props: {
    fieldKey:String,
    label:String,
    translatedLabel2:String,
    settings:Object,
    options:Array,
    isValid:Boolean,
    placeholder:String,
    required:Boolean,
    fieldValue:String
  },
  data: function () {
    return {
      fieldval: ''
    };
  },
  watch: {
    fieldValue (newVal, oldVal) {
      console.log("watched",this.fieldKey, this.label, 'old:',oldVal, 'new:',newVal);
      this.fieldval = newVal ? newVal : oldVal
      console.log('this.fieldval', this.fieldval)
    }
  },
  computed: {},
  created() {},
  mounted: function () {},
  methods: {
    notifyParent(event) {
      this.$emit('changed', event);
    }
  }
}
</script>

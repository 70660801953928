import { render, staticRenderFns } from "./Presenze.vue?vue&type=template&id=55c49c0e&scoped=true"
import script from "./Presenze.vue?vue&type=script&lang=js"
export * from "./Presenze.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c49c0e",
  null
  
)

export default component.exports
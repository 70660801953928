<template>

  <div class="row">
    <div class="col">

      <div class="row">
        <div class="col-5 offset-1">
          <div class="form-group ">
            <label class="control-label">USERNAME</label>
            <input v-model="username" maxlength="100" type="text" class="form-control"
              :placeholder="$t('Inserisciun')" />
          </div>
        </div>
        <div class="col-5">
          <div class="form-group ">
            <label class="control-label">PASSWORD</label>
            <div class="input-group">
              <input v-model="password" maxlength="100" v-bind:type="reveal ? 'text' : 'password'" class="form-control"
                placeholder="Password" />
              <div class="input-group-append" v-on:click="chgReveal">
                <span class="input-group-text">
                  <span v-if="!reveal" class="oi oi-eye"></span>
                  <span v-if="reveal" class="oi oi-code"></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5 offset-1">
          <div class="form-group">
            <!-- <span v-on:click.prevent="$emit('signup')" v-if="this.$i18n.locale == 'it'">Se desideri registrarti, <u>premi
                qui</u> <br /></span>
            <span v-on:click.prevent="$emit('signup')" v-else>If you want to register, <u>click here</u> <br /></span> -->

            <span v-on:click.prevent="goto" v-if="this.$i18n.locale == 'it'">Password dimenticata? <u>premi qui</u></span>
            <span v-on:click.prevent="goto" v-else>Password forgotten? <u>click here</u></span>
          </div>
        </div>
        <div class="col-5">
          <span v-if="showerror && this.$i18n.locale == 'it'" class="text-danger">Credenziali errate</span>
          <span v-else-if="showerror && this.$i18n.locale == 'en'" class="text-danger">Wrong credentials</span>

          <span v-if="showblocked && this.$i18n.locale == 'it'" class="text-danger">Utente Bloccato: <br> Contattare la
            Segreteria Studenti o scrivere una mail a <a href="mailto:segreteria@scuolacucina.it"
              style="font-size:18px;padding-left:5px;">segreteria@scuolacucina.it</a></span>
          <span v-else-if="showblocked && this.$i18n.locale == 'en'" class="text-danger">Blocked User: <br> Contact the
            Student Secreteriat or send an email at <a href="mailto:segreteria@scuolacucina.it"
              style="font-size:18px;padding-left:5px;">segreteria@scuolacucina.it</a></span>
          <button type="submit" class="btn btn-primary float-right" :disabled="btnDoLoginDisabled ? true : false"
            v-on:click.prevent="doLogin">{{ $t('Invia') }}</button>
        </div>
      </div>

      <div class="container mt-5">

        <div class="d-flex flex-column align-items-center row">
          <div class="col-10 mb-2">
            <a @click.prevent="handleProviderSignIn('google', true,1)" :class="{'pe-none': disableLinks }">
              <button class="btn btnBlue btn-block"><i class="bi bi-google mr-2"></i>{{ $t('accediGoogle') }}</button>
            </a>
          </div>
          <div class="col-10">
            <a @click.prevent="handleProviderSignIn('apple', true,1)" :class="{'pe-none': disableLinks }">
              <button class="btn btnBlue btn-block"><i class="bi bi-apple mr-2"></i>{{ $t('accediApple') }}</button>
            </a>
          </div>
          <div class="col-10 mb-2 ml-4 my-3">
            <a @click.prevent="showStepForPlainLogin()" :class="{'pe-none': disableLinks }" style="text-decoration: underline">{{ $t('creaAccount') }}</a>
          </div>
        </div>
        <!-- Custom Google Sign-In Button -->
        <!-- <a class="custom-google-signin" v-on:click.prevent="handleProviderSignIn('google')">
          <img src="https://upload.wikimedia.org/wikipedia/commons/1/18/Gile_use.png" style="width: 40px; height: auto;"
            alt="Google Logo"> 
        </a>-->

        <!-- Custom Apple Sign-In Button -->
        <!-- <a class="custom-apple-signin" v-on:click.prevent="handleProviderSignIn('apple')">
          <img src="https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg"
            style="width: 40px; height: auto;" alt="Apple Logo">
        </a> -->

        <!-- <button class="custom-apple-signin" v-on:click.prevent="testfunction"> test</button> -->

      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'singin',
  props: {},
  data: function () {
    return {
      username: '',
      password: '',
      show: false,
      showerror: false,
      showblocked: false,
      reveal: false,
      btnDoLoginDisabled: false,
    }
  },
  methods: {

    chgReveal: function () {
      this.reveal = !this.reveal;
    },
    handleProviderSignIn: function (provider) {
      let payload = {provider:provider}

      this.$store.dispatch('signInWithProvider', payload)
    },
    doLogin: function () {
      var self = this;
      self.btnDoLoginDisabled = true
      var data = { username: this.username, password: this.password };
      self.showerror = false;
      self.showblocked = false;
      if (this.username != '' && this.password != '') {
        this.$store.dispatch('login', data)
          .then(function () {
            if (self.$store.state.connection.userdata && self.$store.state.blocked === false) {
              var data2 = { username: self.$store.state.connection.username, crypt: self.$store.state.connection.userdata };
              self.$store.dispatch('getProfile', data2).then(function () {
                if (self.$store.state.userprofile.Lingua__c) {
                  console.log('set personal language to:' + self.$store.state.userprofile.Lingua__c)
                  self.$i18n.locale = self.$store.state.userprofile.Lingua__c;
                } else {
                  self.$i18n.locale = 'it'; // default to it
                }
                console.log('profile recoveder');
                self.$emit('logged');
              });
            } else if (!self.$store.state.connection.userdata && self.$store.state.blocked !== true) {
              self.showerror = true;
            } else if (self.$store.state.blocked === true) {
              self.showblocked = true;
            }
            self.btnDoLoginDisabled = false
          })
      }
      else {
        self.showerror = true
        self.btnDoLoginDisabled = false
      }
    },
    goto: function () {
      this.$router.push({ name: 'InvioEmailRecovery' });
    }
  }
}
</script>

<style scoped>
/* Container */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}

/* Google Sign-In Button */
.custom-google-signin {
  padding: 12px 24px;
  background-color: #4285F4;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 120px;
  transition: all 0.3s ease;
}

.custom-google-signin:hover {
  background-color: #357AE8;
  transform: translateY(-2px);
}

/* Apple Sign-In Button */
.custom-apple-signin {
  padding: 12px 24px;
  background-color: #000000;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 120px;
  transition: all 0.3s ease;
}

.custom-apple-signin:hover {
  background-color: #333333;
  transform: translateY(-2px);
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.login-button img {
  height: 20px;
  margin-right: 10px;
}

.login-button:hover {
  background-color: #f5f5f5;
}

.apple-button img {
  height: 24px;
  /* Slightly larger for Apple logo */
  margin: 0;
  /* No margin for Apple button */
}
</style>
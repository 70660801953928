<template>
  <div class="panel-heading col-sm-12 col-md-12">
    <h3 class="panel-title">{{ translatedLabel }}</h3>
  </div>
</template>

<script>
export default {
  name: 'myTitle',
  props: {
    label: String,
    translatedLabel:String
  },
}
</script>
